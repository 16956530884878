/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Time = props => {
  const { color, width, height, withBorder, isActive, activeColor } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#12ABAE" />
          <stop offset="100%" stopColor="#88C69A" />
        </linearGradient>
      </defs>
      {withBorder && (
        <rect
          width="23"
          height="23"
          x=".5"
          y=".5"
          stroke={isActive ? activeColor : color}
          fill={isActive ? color : 'none'}
          rx="2"
        />
      )}
      <g stroke={isActive ? activeColor : color}>
        <path d="M12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8a.5.5 0 0 1 0 1c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7a.5.5 0 1 1 1 0c0 4.411-3.589 8-8 8z" />
        <path d="M12 5a.526.526 0 0 1-.355-.145.526.526 0 0 1-.145-.355c0-.13.055-.26.145-.355a.524.524 0 0 1 .71 0c.09.095.145.225.145.355 0 .13-.055.26-.145.355A.526.526 0 0 1 12 5z" />
        <path
          fillRule="nonzero"
          d="M18.76 10.185a.501.501 0 0 1 .355-.61.5.5 0 0 1 .615.35c.07.27-.09.54-.355.615a.501.501 0 0 1-.615-.355zm-.7-1.685h.005a.5.5 0 0 1 .864-.5.5.5 0 0 1-.184.68.511.511 0 0 1-.25.07c-.17 0-.34-.09-.435-.25zm-1.11-1.45a.498.498 0 1 1 .705-.705h.004c.191.195.191.51-.004.705a.48.48 0 0 1-.35.145.485.485 0 0 1-.356-.145zM15.5 5.935a.493.493 0 0 1-.18-.68c.135-.24.44-.32.68-.185.24.14.32.445.185.685a.504.504 0 0 1-.435.25.511.511 0 0 1-.25-.07zm-1.685-.695a.5.5 0 1 1 .255-.97h.005a.501.501 0 1 1-.13.985.545.545 0 0 1-.13-.015z"
        />
        <path d="M19.5 12.5a.515.515 0 0 1-.355-.145A.523.523 0 0 1 19 12c0-.13.054-.26.145-.355.18-.185.52-.185.71 0 .09.095.145.225.145.355 0 .13-.055.26-.145.355a.526.526 0 0 1-.355.145zM15 14.5a.502.502 0 0 1-.277-.084l-3-2A.5.5 0 0 1 11.5 12V7a.5.5 0 0 1 1 0v4.732l2.777 1.852A.5.5 0 0 1 15 14.5z" />
      </g>
    </svg>
  );
};

Time.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  withBorder: PropTypes.bool,
};

Time.defaultProps = {
  color: 'url(#gradient)',
  width: '40px',
  height: '40px',
  withBorder: true,
};

export default Time;

import * as actionTypes from '@/store/actions/types/btlManager';

export const createBtlManager = (requestData, callback = () => {}) => {
  return {
    type: actionTypes.BTL_MANAGER_CREATE,
    payload: {
      requestData,
      callback,
    },
  };
};

export const updateBtlManager = (id, requestData, callback = () => {}) => {
  return {
    type: actionTypes.BTL_MANAGER_UPDATE,
    payload: {
      id,
      requestData,
      callback,
    },
  };
};

export default updateBtlManager;

import axios from 'axios';

export const deleteAuthorizationToken = () => {
  delete axios.defaults.headers.Authorization;
};

export const setAuthorizationToken = token => {
  if (token) {
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  } else {
    deleteAuthorizationToken();
  }
};

import * as actionTypes from './types/regions';

export const getRegionsList = params => {
  return {
    type: actionTypes.REGIONS_GET_LIST,
    payload: { params },
  };
};

export default getRegionsList;

import * as actionTypes from './types/auth';

export const auth = data => {
  return {
    type: actionTypes.AUTH_USER,
    payload: {
      data,
    },
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const forgotPassword = data => {
  return {
    type: actionTypes.AUTH_FORGOT_PASSWORD,
    payload: {
      data,
    },
  };
};

export const resetPassword = data => {
  return {
    type: actionTypes.AUTH_RESET_PASSWORD,
    payload: {
      data,
    },
  };
};

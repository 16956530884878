// -- Endpoints --

// approvals list
export const GET_APPROVALS_LIST = '/api/approval/:type/list';
export const GET_APPROVALS_COUNT = '/api/approval/total-count';
export const APPROVALS_RESEND = '/api/approval/resend';
export const APPROVALS_DTC_SEND = '/api/approval/crmusers/send';

export const APPROVAL_SEND = '/api/approval/:userType/:type';

export default GET_APPROVALS_LIST;

import { createAction } from 'redux-actions';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = '@notification/REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = '@notification/CLEAR_NOTIFICATIONS';
export const DEFAULT_TIME_LIFE_NOTIFICATION = 5000;
export const addNotificationAction = createAction(ADD_NOTIFICATION);
export const clearNotificationAction = createAction(REMOVE_NOTIFICATION);

export const addNotification = notification => dispatch => {
  // console.log("notification", notification);
  // await dispatch(addNotificationAction(notification));
  dispatch({
    type: ADD_NOTIFICATION,
    payload: notification,
  });
  setTimeout(() => dispatch(clearNotificationAction()), DEFAULT_TIME_LIFE_NOTIFICATION);
};

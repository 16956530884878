export { auth, logout, forgotPassword, resetPassword } from './auth';

export {
  getAgentsList,
  getAgentsLazyList,
  clearAgentsList,
  getAgentInfoEdit,
  createUpdateAgent,
} from './agents';
export { exportAgents, downloadFile } from './files';
export { getRegionsList } from './regions';

export { createBtlManager, updateBtlManager } from './btlManager';
export { updateDtcManager } from './dtcManager';
export { getManagerProfile } from './manager';

export { getCommentsList, addComment } from './comments';

export { createBtlSupervisor, editBtlSupervisor } from './btlSupervisors';

export { getApprovalsList, requestApprovalChanges, getApprovalsCounts } from './approvals';

export { sendApproval } from './approval';

export { addTest, editTest, getTest, getTestTypes } from './test';

export { getManagersList } from './managers';

export {
  getDictionaries,
  getDictionariesSupervisor,
  getDictionariesSupervisorRegion,
} from './dictionaries';

export { getCountries } from './countries';

export { popupAddedSuccess, popupApprovalRequest, popupAlbum } from './popups';

export { notEnoughEights } from './notEnoughRights';

export { getAgentProfile } from './agent';

export {
  getPhotoList,
  getSelectList,
  getSliderPhotoList,
  createAlbum,
  getOutletsList,
  getPhotoDetailList,
  deletePhoto,
  selectListClean,
  addPhotoToAlbum,
} from './photoArchive';

export {
  getTestsInstructions,
  getTestsPassList,
  getTestsResults,
  getTestsAllList,
  getTestDetail,
  getTestHistoryList,
  exportTestsPassList,
} from './tests';

export { getTestPass, setTestAnswer, setTestComplete, clearPassTest } from './passTest';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// COMMON
import { PopupAddedSuccess } from '@/components/parts/Popups';
import * as actions from '@/store/actions/popups';

const layout = ({ children, addedSuccessState, popupAddedSuccess }) => {
  return (
    <>
      {children}
      {addedSuccessState && <PopupAddedSuccess onClose={() => popupAddedSuccess(false)} />}
    </>
  );
};

layout.defaultProps = {};

layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  addedSuccessState: PropTypes.bool.isRequired,
  popupAddedSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = ({ popups }) => {
  return {
    addedSuccessState: popups.addedSuccessState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    popupAddedSuccess: state => dispatch(actions.popupAddedSuccess(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(layout);

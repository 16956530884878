import * as actionTypes from './types/passTest';
import { createAction } from '@/utillity/createAction';

export const getTestPass = id => {
  return createAction(actionTypes.GET_TEST.INIT, { id });
};

export const setTestAnswer = (id, data, isCompleted = false) => {
  return createAction(actionTypes.SET_TEST_ANSWER.INIT, { id, data, isCompleted });
};

export const setTestComplete = id => {
  return createAction(actionTypes.SET_TEST_COMPLETE.INIT, { id });
};

export const clearPassTest = () => {
  return createAction(actionTypes.CLEAR_PASS_TEST.INIT);
};

export default getTestPass;

import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/passTest';
import * as endpoints from '@/const/api/passTest';
import getLink from '@/utillity/routes';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import history from '@/utillity/history';

export function* getTestPassSaga(action) {
  yield put({ type: actionTypes.GET_TEST.START });

  const { id } = action.payload;
  const url = getLink(endpoints.TEST_PASSING, { id });

  try {
    // yield axios.post('api/online-test/clear-me');
    const {
      data: { data },
    } = yield axios.get(url);

    yield put({
      type: actionTypes.GET_TEST.SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
    yield call(history.goBack);
    yield put({
      type: actionTypes.GET_TEST.FAIL,
      payload: { error },
    });
  }
}

export function* setTestCompleteSaga(action) {
  yield put({ type: actionTypes.SET_TEST_COMPLETE.START });

  const { id } = action.payload;
  const url = getLink(endpoints.TEST_COMPLETE, { id });

  try {
    const {
      data: { data },
    } = yield axios.post(url);

    yield put({
      type: actionTypes.SET_TEST_COMPLETE.SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
    yield call(history.goBack);
    yield put({
      type: actionTypes.SET_TEST_COMPLETE.FAIL,
      payload: { error },
    });
  }
}

export function* setTestAnswerSaga(action) {
  yield put({ type: actionTypes.SET_TEST_ANSWER.START });

  const { id, data, isCompleted } = action.payload;
  const url = getLink(endpoints.TEST_SET_ANSWER, { id });

  try {
    yield axios.post(url, data);
    yield put({
      type: actionTypes.SET_TEST_ANSWER.SUCCESS,
    });
    if (isCompleted) {
      yield call(setTestCompleteSaga, action);
    }
  } catch (error) {
    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
    yield call(history.goBack);
    yield put({
      type: actionTypes.SET_TEST_ANSWER.FAIL,
      payload: { error },
    });
  }
}

export default getTestPassSaga;

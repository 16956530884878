import * as actionTypes from '@/store/actions/types/tests';

const initialState = {
  instructions: null,
  results: {
    list: [],
    total_pages: 1,
    current_page: 1,
  },
  passList: {
    list: [],
    total_pages: 1,
    current_page: 1,
  },
  allList: {
    list: [],
    total_pages: 1,
    current_page: 1,
  },
  testHistoryList: {
    list: [],
    total_pages: 1,
    current_page: 1,
  },
  detail: null,
  error: {
    detail: null,
    instructions: null,
    passList: null,
    results: null,
    allList: null,
    testHistoryList: null,
    export: null,
  },
  loadings: {
    testHistoryList: true,
    detail: true,
    instructions: true,
    passList: true,
    results: true,
    allList: true,
    export: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TESTS_INSTRUCTIONS.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          instructions: true,
        },
      };
    case actionTypes.GET_TESTS_INSTRUCTIONS.SUCCESS:
      return {
        ...state,
        instructions: action.payload.data,
        loadings: {
          ...state.loadings,
          instructions: false,
        },
      };
    case actionTypes.GET_TESTS_INSTRUCTIONS.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          instructions: false,
        },
        errors: {
          ...state.errors,
          instructions: action.payload.error,
        },
      };
    case actionTypes.GET_TESTS_PASS_LIST.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          passList: true,
        },
      };
    case actionTypes.GET_TESTS_PASS_LIST.SUCCESS:
      return {
        ...state,
        passList: {
          list: action.payload.data.data,
          total_pages: action.payload.data.meta.last_page,
          current_page: action.payload.data.meta.current_page,
        },
        loadings: {
          ...state.loadings,
          passList: false,
        },
      };
    case actionTypes.GET_TESTS_PASS_LIST.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          passList: false,
        },
        errors: {
          ...state.errors,
          passList: action.payload.error,
        },
      };
    case actionTypes.GET_TESTS_RESULTS.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          results: true,
        },
      };
    case actionTypes.GET_TESTS_RESULTS.SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          list: action.payload.data,
          total_pages: action.payload.meta.last_page,
          current_page: action.payload.meta.current_page,
        },
        loadings: {
          ...state.loadings,
          results: false,
        },
      };
    case actionTypes.GET_TESTS_RESULTS.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          results: false,
        },
        errors: {
          ...state.errors,
          results: action.payload.error,
        },
      };
    case actionTypes.GET_TESTS_ALL_LIST.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          allList: true,
        },
      };
    case actionTypes.GET_TESTS_ALL_LIST.SUCCESS:
      return {
        ...state,
        allList: {
          list: action.payload.data.data,
          total_pages: action.payload.data.meta.last_page,
          current_page: action.payload.data.meta.current_page,
        },
        loadings: {
          ...state.loadings,
          allList: false,
        },
      };
    case actionTypes.GET_TESTS_ALL_LIST.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          allList: false,
        },
        errors: {
          ...state.errors,
          allList: action.payload.error,
        },
      };
    case actionTypes.GET_TEST_DETAIL.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          detail: true,
        },
      };
    case actionTypes.GET_TEST_DETAIL.SUCCESS:
      return {
        ...state,
        detail: action.payload.data,
        loadings: {
          ...state.loadings,
          detail: false,
        },
      };
    case actionTypes.GET_TEST_DETAIL.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          detail: false,
        },
        error: {
          ...state.error,
          detail: action.payload.error,
        },
      };
    case actionTypes.GET_TEST_HISTORY_LIST.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          testHistoryList: true,
        },
      };
    case actionTypes.GET_TEST_HISTORY_LIST.SUCCESS:
      return {
        ...state,
        testHistoryList: {
          list: action.payload.data,
          total_pages: action.payload.meta.last_page,
          current_page: action.payload.meta.current_page,
        },
        loadings: {
          ...state.loadings,
          testHistoryList: false,
        },
      };
    case actionTypes.GET_TEST_HISTORY_LIST.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          testHistoryList: false,
        },
        error: {
          ...state.error,
          testHistoryList: action.payload.error,
        },
      };
    case actionTypes.EXPORT_TESTS_PASS_LIST.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          export: true,
        },
      };
    case actionTypes.EXPORT_TESTS_PASS_LIST.SUCCESS:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          export: false,
        },
      };
    case actionTypes.EXPORT_TESTS_PASS_LIST.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          export: false,
        },
        errors: {
          ...state.errors,
          export: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

// -- Endpoints --

// Btl Supervisors
export const BTL_SUPERVISORS_CREATE = '/api/crmusers/btl-supervisor';
export const BTL_SUPERVISORS_EDIT = '/api/crmusers/btl-supervisor/:id';

// Btl Manager
export const BTL_MANAGER_CREATE = '/api/crmusers/btl-manager';
export const BTL_MANAGER_EDIT = '/api/crmusers/btl-manager/:id';

// Dtc Manager
export const DTC_MANAGER_EDIT = '/api/crmusers/dtc-manager/:id';

// managers list
export const GET_MANAGERS_LIST = '/api/crmusers/:type';

// managers profile
export const GET_MANAGER_PROFILE = '/api/crmusers/show/:id';

import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/dtcManger';
import * as managerEndpoints from '@/const/api/managers';
import { isArray, isObject } from 'lodash-es';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import getLink from '@/utillity/routes';

function updateFormData(formData, key, data) {
  if (isObject(data) || isArray(data)) {
    if (key === 'Avatar') {
      return formData.append(key, data);
    }
    // eslint-disable-next-line guard-for-in
    for (const i in data) {
      updateFormData(formData, `${key}[${i}]`, data[i]);
    }
  } else {
    formData.append(key, data);
  }
}

export function* updateDtcManagerSaga(action) {
  yield put({ type: actionTypes.DTC_MANAGER_UPDATE_START });

  const { id, requestData, callback } = action.payload;
  const url = getLink(managerEndpoints.DTC_MANAGER_EDIT, { id });
  const formData = new FormData();

  Object.keys(requestData).forEach(key => {
    updateFormData(formData, key, requestData[key]);
  });

  formData.append('_method', 'PUT');

  try {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const { data } = yield axios.post(url, formData, config);

    yield put({
      type: actionTypes.DTC_MANAGER_UPDATE_SUCCESS,
      payload: { data },
    });

    callback();
  } catch (error) {
    yield put({
      type: actionTypes.DTC_MANAGER_UPDATE_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export default updateDtcManagerSaga;

import * as Yup from 'yup';

import LoginSchema from './schemas/LoginShema';
import ForgotPasswordSchema from './schemas/ForgotPasswordSchema';
import ResetPasswordSchema from './schemas/ResetPasswordSchema';
import CommentSchema from './schemas/CommentSchema';
import CreateEmployeeProfileSchema from './schemas/CreateEmployeeProfileSchema';
import EditEmployeeProfileSchema from './schemas/EditEmployeeProfileSchema';
import CreateBtlManagerSchema from './schemas/CreateBtlManagerSchema';
import EditBtlManagerSchema from './schemas/EditBtlManagerSchema';
import EditDtcManagerSchema from './schemas/EditDtcManagerSchema';
import CreateSupervisorSchema from './schemas/CreateSupervisorSchema';
import EditSupervisorSchema from './schemas/EditSupervisorSchema';
import FilterFormSchema from './schemas/FilterFormSchema';
import TestingAddEditSchema from './schemas/TestingAddEditSchema';
import ArchiveSearch from './schemas/ArchiveSearch';
import CreateAlbum from './schemas/CreateAlbum';

const setYupValidationSchema = schemaName => {
  let schema;

  switch (schemaName) {
    case 'LoginSchema':
      schema = LoginSchema();
      break;
    case 'ForgotPasswordSchema':
      schema = ForgotPasswordSchema();
      break;
    case 'ResetPasswordSchema':
      schema = ResetPasswordSchema();
      break;
    case 'CommentSchema':
      schema = CommentSchema();
      break;
    case 'CreateEmployeeProfileSchema':
      schema = CreateEmployeeProfileSchema();
      break;
    case 'EditEmployeeProfileSchema':
      schema = EditEmployeeProfileSchema();
      break;
    case 'CreateBtlManagerSchema':
      schema = CreateBtlManagerSchema();
      break;
    case 'EditBtlManagerSchema':
      schema = EditBtlManagerSchema();
      break;
    case 'CreateSupervisorSchema':
      schema = CreateSupervisorSchema();
      break;
    case 'EditSupervisorSchema':
      schema = EditSupervisorSchema();
      break;
    case 'EditDtcManagerSchema':
      schema = EditDtcManagerSchema();
      break;
    case 'FilterFormSchema':
      schema = FilterFormSchema();
      break;
    case 'TestingAddEditSchema':
      schema = TestingAddEditSchema();
      break;
    case 'ArchiveSearch':
      schema = ArchiveSearch();
      break;
    case 'CreateAlbum':
      schema = CreateAlbum();
      break;
    default:
      schema = {};
      break;
  }

  return Yup.object().shape(schema);
};

export default setYupValidationSchema;

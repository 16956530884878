import { createActionType } from '@/utillity/createAction';

export const GET_TESTS_INSTRUCTIONS = createActionType('GET_TESTS_INSTRUCTIONS');
export const GET_TESTS_RESULTS = createActionType('GET_TESTS_RESULTS');
export const GET_TESTS_PASS_LIST = createActionType('GET_TESTS_PASS_LIST');
export const EXPORT_TESTS_PASS_LIST = createActionType('EXPORT_TESTS_PASS_LIST');

export const GET_TESTS_ALL_LIST = createActionType('GET_TESTS_ALL_LIST');
export const GET_TEST_DETAIL = createActionType('GET_TEST_DETAIL');
export const GET_TEST_HISTORY_LIST = createActionType('GET_TEST_HISTORY_LIST');

export const rankData = [
  { label: 'Hostess', value: 1 },
  { label: 'Promoter', value: 0 },
];

export const clothSizes = [
  { label: 'XS', value: 'XS', order: 0 },
  { label: 'S', value: 'S', order: 1 },
  { label: 'M', value: 'M', order: 2 },
  { label: 'L', value: 'L', order: 3 },
  { label: 'XL', value: 'XL', order: 4 },
];

export const shoeSize = [
  { label: '35', value: 35 },
  { label: '36', value: 36 },
  { label: '37', value: 37 },
  { label: '38', value: 38 },
  { label: '39', value: 39 },
  { label: '40', value: 40 },
  { label: '41', value: 41 },
  { label: '42', value: 42 },
  { label: '43', value: 43 },
  { label: '44', value: 44 },
  { label: '45', value: 45 },
  { label: '46', value: 46 },
];

export const heightOptions = new Array(151).fill().map((e, i) => {
  const value = i + 100;
  return {
    label: value.toString(),
    value,
  };
});

export const trainingsOptions = [
  {
    value: 'NLP International 2018',
    label: 'NLP International 2018',
  },
  {
    value: 'Trade Guru 2018',
    label: 'Trade Guru 2018',
  },
  {
    value: 'Supervisor Master 2019',
    label: 'Supervisor Master 2019',
  },
  {
    value: 'Managers WWC 2018',
    label: 'Managers WWC 2018',
  },
];

export const languageOptions = [
  {
    value: 'Does not speak',
    label: 'Does not speak',
  },
  {
    value: 'Basic',
    label: 'Basic',
  },
  {
    value: 'Intermediate',
    label: 'Intermediate',
  },
  {
    value: 'Advanced',
    label: 'Advanced',
  },
  {
    value: 'Native',
    label: 'Native',
  },
];

export const staticLanguages = [
  { name: 'ENG', apiName: 'EN' },
  { name: 'AR', apiName: 'AR' },
  { name: 'GE', apiName: 'GE' },
  { name: 'AZ', apiName: 'AZ' },
  { name: 'RU', apiName: 'RU' },
  // { name: 'BY', apiName: 'BY' },
];

export const genderData = [
  { label: 'Male', value: 'm' },
  { label: 'Female', value: 'f' },
];

import * as Yup from 'yup';
import { clothSizes, shoeSize, heightOptions } from '@/components/forms/EmployeeForm/helpers';

function compareObjects(ref, msg, arr = [], key = 'value') {
  return this.test({
    name: 'compareObjects',
    exclusive: false,
    message: msg,
    test(value) {
      if (!value || !this.parent[ref]) {
        return true;
      }
      if (arr.length > 0) {
        const values = arr.map(el => el[key]);
        return values.indexOf(value) >= values.indexOf(this.parent[ref]);
      }
    },
  });
}

Yup.addMethod(Yup.string, 'compareObjects', compareObjects);

export default () => {
  return {
    regionId: Yup.string().nullable(),
    cityId: Yup.string().nullable(),
    rank: Yup.string().nullable(),
    status: Yup.string().nullable(),
    cloth_size_from: Yup.string().nullable(),
    cloth_size_to: Yup.string()
      .compareObjects('cloth_size_from', 'To must be less then From', clothSizes)
      .nullable(),
    shoe_size_from: Yup.string().nullable(),
    shoe_size_to: Yup.string()
      .compareObjects('shoe_size_from', 'To must be less then From', shoeSize, 'label')
      .nullable(),
    gender: Yup.string(),
    height_from: Yup.string().nullable(),
    height_to: Yup.string()
      .compareObjects('height_from', 'To must be less then From', heightOptions, 'label')
      .nullable(),
  };
};

/* eslint-disable no-case-declarations */
import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/photoArchive';
import * as endpoints from '@/const/api/photoArchive';
import getLink from '@/utillity/routes';
import { isArray, isObject } from 'lodash-es';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import history from '@/utillity/history';
import { ROUTE_ARCHIVE_DETAIL, ROUTE_PHOTO_ARCHIVE } from '@/const/routes';

function createFormData(formData, key, data) {
  if (isObject(data) || isArray(data)) {
    if (key === 'album') {
      // eslint-disable-next-line guard-for-in
      for (const index in data) {
        // eslint-disable-next-line guard-for-in
        for (const itemKey in data[index]) {
          formData.append(`${key}[${index}][${itemKey}]`, data[index][itemKey]);
        }
      }
      return;
    }
    // eslint-disable-next-line guard-for-in
    for (const i in data) {
      createFormData(formData, `${key}[${i}]`, data[i]);
    }
  } else {
    formData.append(key, data);
  }
}

export function* getPhotoListSaga(action) {
  yield put({ type: actionTypes.PHOTO_GET_LIST_START });

  const { params } = action.payload;

  try {
    const {
      data: { data, meta },
    } = yield axios.get(endpoints.GET_ARCHIVES, { params });

    yield put({
      type: actionTypes.PHOTO_GET_LIST_SUCCESS,
      payload: { data, meta },
    });
  } catch (error) {
    yield put({
      type: actionTypes.PHOTO_GET_LIST_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* getPhotoDetailListSaga(action) {
  yield put({ type: actionTypes.DETAIL_GET_LIST_START });

  const { id, params } = action.payload;

  try {
    const {
      data: { data, meta },
    } = yield axios.get(getLink(endpoints.GET_DETAIL_ALBUM, { id }), { params });

    yield put({
      type: actionTypes.DETAIL_GET_LIST_SUCCESS,
      payload: { data, meta },
    });
  } catch (error) {
    yield put({
      type: actionTypes.DETAIL_GET_LIST_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* getSelectListSaga(action) {
  yield put({ type: actionTypes.SELECT_GET_LIST_START });

  const { type, params } = action.payload;

  let yearsRes;
  let activitiesRes;
  let supervisorsRes;

  try {
    switch (type) {
      case 'year':
        activitiesRes = yield axios.get(endpoints.GET_ACTIVITY, { params });
        supervisorsRes = yield axios.get(endpoints.GET_SUPERVISORS, { params });

        yield put({
          type: actionTypes.SELECT_ACTIVITY_LIST_SUCCESS,
          payload: activitiesRes.data,
        });
        yield put({
          type: actionTypes.SELECT_SUPERVISORS_LIST_SUCCESS,
          payload: supervisorsRes.data,
        });
        break;
      case 'activity_id':
        yearsRes = yield axios.get(endpoints.GET_YEARS, { params });
        supervisorsRes = yield axios.get(endpoints.GET_SUPERVISORS, { params });

        yield put({
          type: actionTypes.SELECT_YEAR_LIST_SUCCESS,
          payload: yearsRes.data,
        });
        yield put({
          type: actionTypes.SELECT_SUPERVISORS_LIST_SUCCESS,
          payload: supervisorsRes.data,
        });

        break;
      case 'manager_id':
        yearsRes = yield axios.get(endpoints.GET_YEARS, { params });
        activitiesRes = yield axios.get(endpoints.GET_ACTIVITY, { params });

        yield put({
          type: actionTypes.SELECT_YEAR_LIST_SUCCESS,
          payload: yearsRes.data,
        });
        yield put({
          type: actionTypes.SELECT_ACTIVITY_LIST_SUCCESS,
          payload: activitiesRes.data,
        });
        break;

      default:
        yearsRes = yield axios.get(endpoints.GET_YEARS, { params });
        activitiesRes = yield axios.get(endpoints.GET_ACTIVITY, { params });
        supervisorsRes = yield axios.get(endpoints.GET_SUPERVISORS, { params });

        yield put({
          type: actionTypes.SELECT_YEAR_LIST_SUCCESS,
          payload: yearsRes.data,
        });
        yield put({
          type: actionTypes.SELECT_ACTIVITY_LIST_SUCCESS,
          payload: activitiesRes.data,
        });
        yield put({
          type: actionTypes.SELECT_SUPERVISORS_LIST_SUCCESS,
          payload: supervisorsRes.data,
        });
        break;
    }

    yield put({ type: actionTypes.SELECT_GET_LIST_STOP });
  } catch (error) {
    yield put({
      type: actionTypes.SELECT_GET_LIST_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* getSliderListSaga(action) {
  yield put({ type: actionTypes.SLIDER_PHOTO_GET_LIST_START });

  const { id } = action.payload;

  try {
    const { data } = yield axios.get(getLink(endpoints.GET_SLIDER, { id }));

    yield put({
      type: actionTypes.SLIDER_PHOTO_GET_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.SLIDER_PHOTO_GET_LIST_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* createAlbumSaga(action) {
  yield put({ type: actionTypes.CREATE_ALBUM_START });

  const { requestData, callback } = action.payload;
  const url = endpoints.CREATE_ALBUM;

  const formData = new FormData();
  Object.keys(requestData).forEach(key => {
    createFormData(formData, key, requestData[key]);
  });

  try {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const { data } = yield axios.post(url, formData, config);

    yield put({
      type: actionTypes.CREATE_ALBUM_SUCCESS,
      payload: { data },
    });

    callback();
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_ALBUM_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* addPhotoToAlbumSaga(action) {
  yield put({ type: actionTypes.ADD_PHOTO_TO_ALBUM_START });

  const { requestData, callback } = action.payload;
  const url = getLink(endpoints.ADD_PHOTO_TO_ALBUM, { id: requestData.id });
  const formData = new FormData();
  const album = { album: requestData.album };

  Object.keys(album).forEach(key => {
    createFormData(formData, key, requestData[key]);
  });
  formData.append('_method', 'put');

  try {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const { data } = yield axios.post(url, formData, config);

    yield put({
      type: actionTypes.ADD_PHOTO_TO_ALBUM_SUCCESS,
      payload: { data },
    });

    callback();
  } catch (error) {
    yield put({
      type: actionTypes.ADD_PHOTO_TO_ALBUM_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* getOutletListSaga(action) {
  yield put({ type: actionTypes.OUTLET_GET_LIST_START });

  const { params } = action.payload;

  try {
    const url = endpoints.GET_OUTLET;
    const {
      data: { data, meta },
    } = yield axios.get(url, { params });

    yield put({
      type: actionTypes.OUTLET_GET_LIST_SUCCESS,
      payload: { data, meta },
    });
    yield put({
      type: actionTypes.OUTLET_GET_LIST_STOP,
    });
  } catch (error) {
    yield put({
      type: actionTypes.OUTLET_GET_LIST_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* deletePhotoSaga(action) {
  yield put({ type: actionTypes.DELETE_PHOTO_START });

  const {
    data: { type, AlbumId, ids },
  } = action.payload;
  let url;
  let bodyIds;

  if (type === 'album') {
    url = endpoints.GET_ARCHIVES;
    bodyIds = { AlbumIds: ids };
  } else {
    url = endpoints.DELETE_PHOTO;
    bodyIds = { AlbumId: +AlbumId, PhotoIds: ids };
  }

  try {
    const {
      data: { albumDeleted },
    } = yield axios.delete(url, { data: bodyIds });

    if (albumDeleted === false) {
      yield call(history.push, getLink(ROUTE_ARCHIVE_DETAIL, { id: AlbumId }));
    } else {
      yield call(history.push, getLink(ROUTE_PHOTO_ARCHIVE, { type: 'archive' }));
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_PHOTO_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

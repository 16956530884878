/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

const User = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
    <g fill="none" fillRule="evenodd">
      <path d="M-2-3h24v24H-2z" />
      <path
        fill="#8A97A0"
        fillRule="nonzero"
        d="M10 12.439c3.02 0 5.478-2.779 5.478-6.193 0-3.415-2.459-6.193-5.478-6.193-1.49 0-2.882.66-3.92 1.86-1.005 1.163-1.558 2.702-1.558 4.333 0 3.414 2.457 6.193 5.478 6.193zm0-11.26c2.349 0 4.26 2.273 4.26 5.067S12.35 11.313 10 11.313c-2.35 0-4.26-2.273-4.262-5.067C5.738 3.452 7.65 1.18 10 1.18zm4.26 10.134c-.336 0-.609.252-.609.563 0 .311.273.563.61.563 2.348 0 4.26 1.769 4.26 3.942 0 .31-.274.563-.609.563H2.088c-.336 0-.609-.253-.609-.563 0-2.173 1.912-3.942 4.26-3.942.337 0 .61-.252.61-.563 0-.31-.273-.563-.61-.563-3.02 0-5.477 2.273-5.477 5.068 0 .931.819 1.689 1.826 1.689h15.824c1.007 0 1.826-.758 1.826-1.69 0-2.794-2.457-5.067-5.478-5.067z"
      />
    </g>
  </svg>
);

export default User;

import * as Yup from 'yup';
import { checkLangLength, checkLangLevel, phoneValidate } from '@/utillity/validationSchema/helper';

export default () => {
  return {
    Avatar: Yup.object()
      .shape({
        Type: Yup.string(),
        Url: Yup.mixed().required('Avatar is required'),
      })
      .required('Avatar is required'),
    Country: Yup.object().required('Country is required'),
    UserName: Yup.string().required('First Name is required'),
    UserSurname: Yup.string().required('Last Name is required'),
    MobilePhone: phoneValidate(),
    UserEmail: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
    Languages: Yup.array()
      .test('is-one-valid', 'Languages is required', checkLangLength)
      .test('is-one-valid', 'level is required', checkLangLevel),
    ComputerSkills: Yup.string(),
    OtherSkills: Yup.string(),
    WorkExperience: Yup.string(),
  };
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

const XMLReport = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
    <g fill="none" fillRule="evenodd">
      <g fill="#7ECFA9">
        <path
          fillRule="nonzero"
          d="M19.25 3.568C19.25 1.605 17.626 0 15.64 0H3.61C1.623 0 0 1.605 0 3.568v14.864C0 20.395 1.624 22 3.61 22h12.03c1.986 0 3.61-1.605 3.61-3.568V3.568zM6.617 1.189h6.016v1.487a1.2 1.2 0 0 1-1.203 1.189H7.82a1.2 1.2 0 0 1-1.203-1.19V1.19zm11.43 17.243c0 1.309-1.083 2.379-2.406 2.379H3.609c-1.323 0-2.406-1.07-2.406-2.379V3.568c0-1.309 1.083-2.379 2.406-2.379h1.805v1.487c0 1.308 1.083 2.378 2.406 2.378h3.61c1.323 0 2.406-1.07 2.406-2.378V1.189h1.805c1.323 0 2.406 1.07 2.406 2.379v14.864z"
        />
        <path d="M5.955 9.216h7.339v1.189H5.955zM4.061 12.486H15.19v1.189H4.061zM4.061 15.757H15.19v1.189H4.061z" />
      </g>
    </g>
  </svg>
);

export default XMLReport;

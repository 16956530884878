import * as actionTypes from '@/store/actions/types/files';
import { put, call } from 'redux-saga/effects';
import * as agentsEndpoints from '@/const/api/agents';
import * as photoArchiveEndpoints from '@/const/api/photoArchive';
import axios from 'axios';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';

export function* exportAgentsListSaga(action) {
  yield put({ type: actionTypes.EXPORT_AGENTS_START });
  const { params } = action.payload;

  const url = agentsEndpoints.AGENTS_EXPORT;

  try {
    const { data } = yield axios.get(url, {
      responseType: 'blob',
      params,
      headers: { Accept: 'application/vnd.ms-excel' },
    });

    const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'XLSX_Report.xlsx';
    link.click();

    yield put({
      type: actionTypes.EXPORT_AGENTS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_AGENTS_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* downloadPhotoSaga(action) {
  yield put({ type: actionTypes.DOWNLOAD_FILE_START });
  const {
    data: { type, ids },
  } = action.payload;

  let url;
  let bodyIds;

  if (type === 'album') {
    url = photoArchiveEndpoints.DOWNLOAD_ALBUM;
    bodyIds = { AlbumIds: ids };
  } else {
    url = photoArchiveEndpoints.DOWNLOAD_PHOTO;
    bodyIds = { PhotoIds: ids };
  }

  try {
    const { data } = yield axios.post(url, bodyIds, {
      responseType: 'blob',
      headers: { Accept: 'application/zip' },
    });

    const blob = new Blob([data], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'file.zip';
    link.click();

    yield put({
      type: actionTypes.DOWNLOAD_FILE_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.DOWNLOAD_FILE_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

import * as actionTypes from './types/agents';

export const getAgentsList = params => {
  return {
    type: actionTypes.AGENTS_GET_LIST,
    payload: { params },
  };
};

export const getAgentsLazyList = params => {
  return {
    type: actionTypes.AGENTS_GET_LAZY_LIST,
    payload: { params },
  };
};

export const clearAgentsList = () => {
  return {
    type: actionTypes.AGENTS_CLEAR_LIST,
  };
};

export const getAgentInfoEdit = id => {
  return {
    type: actionTypes.AGENT_GET_INFO_EDIT,
    payload: {
      id,
    },
  };
};

export const createUpdateAgent = (type, requestData, callback = () => {}) => {
  return {
    type: actionTypes.AGENT_CREATE,
    payload: {
      type,
      requestData,
      callback,
    },
  };
};

export default getAgentsList;

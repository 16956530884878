import * as Yup from 'yup';
import moment from 'moment';

export default () => {
  return {
    TestName: Yup.string().required('Testing name is required'),
    TestType: Yup.number().required('Testing type is required'),
    TestPassingTime: Yup.object()
      .test('TestPassingTime', 'Testing time is required and should be > 10sec', val => {
        return val && moment.duration(val.format('HH:mm:ss')).asSeconds() >= 10;
      })
      .required('Testing time is required and should be > 10sec')
      .nullable(),
    Active: Yup.boolean().required('Active is required'),
    Questions: Yup.array()
      .of(
        Yup.object().shape({
          Active: Yup.boolean(),
          Question: Yup.string().required('Answer is required'),
          Answers: Yup.array().of(
            Yup.object().shape({
              Active: Yup.boolean(),
              // Answer: Yup.string().when('Active', {
              //   is: false,
              //   otherwise: s => s.required('Answer is required'),
              // }),
              Answer: Yup.string().required('Answer is required'),
              CorrectAnswer: Yup.boolean().required('Correct answer is required'),
            }),
          ),
        }),
      )
      .test('Questions', 'Should be at least one Active Question', item => {
        return item.some(val => val.Active);
      })
      .test(
        'Questions',
        'Should be at least one Active and Correct Answer in Every Question',
        item => {
          // console.log(item);
          return item.every(val => {
            return (
              val.Answers && val.Answers.some(el => el.CorrectAnswer && el.Active && el.Answer)
            );
          });
        },
      ),
  };
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { notEnoughEights } from '../store/actions/notEnoughRights';

const CheckAccessHoc = ({ notEnoughEights: openModal, permissions, rights, children }) => {
  const hasAccess = permissions.includes(rights);

  const closeAccess = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    openModal(true);
  };

  return hasAccess ? (
    children
  ) : (
    <>
      {children.props.handleChange
        ? React.cloneElement(children, { onClick: closeAccess, handleChange: closeAccess })
        : React.cloneElement(children, { onClick: closeAccess })}
    </>
  );
};

CheckAccessHoc.propTypes = {
  notEnoughEights: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  rights: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  permissions: auth.permissions,
});

export default connect(mapStateToProps, { notEnoughEights })(CheckAccessHoc);

export const createActionType = name => ({
  INIT: name,
  START: `${name}_START`,
  STOP: `${name}_STOP`,
  SUCCESS: `${name}_SUCCESS`,
  FAIL: `${name}_FAIL`,
});

export const get = (name, params) => ({ type: name, payload: params });

export const createAction = (name, params) => ({ type: name, payload: params });

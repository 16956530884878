import React from 'react';
import PropTypes from 'prop-types';
import IcoAccountAdded from '@/assets/images/popupAddedSuccess/ic-account-added.svg';

const popupAddedSuccess = ({ onClose }) => {
  const onClosePopup = e => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className="Popup fadein">
      <div className="Popup-Bg" />
      <div className="Popup-Wrapper">
        <div className="Popup-Content">
          <div className="Popup-Inner">
            <div className="Popup-Block">
              <button type="button" className="Popup-Close" onClick={onClosePopup} />
              <div className="Popup-Icon">
                <img src={IcoAccountAdded} alt="popup-close-access" />
              </div>
              <h4 className="Popup-Title">Profile added successfully!</h4>
              <div className="Popup-Bottom Center">
                <button type="button" className="Btn Btn-Green Popup-Btn" onClick={onClosePopup}>
                  <span>GOT IT</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

popupAddedSuccess.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default popupAddedSuccess;

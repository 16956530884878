import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/dictionaries';
import * as agentsEndpoints from '@/const/api/dictionaries';

export function* getDictionariesSaga() {
  yield put({ type: actionTypes.DICTIONARIES_GET_START });

  const url = agentsEndpoints.GET_DICTIONARIES;

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.DICTIONARIES_GET_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.DICTIONARIES_GET_FAILED,
      payload: { error },
    });
  }
}

export function* getDictionariesSupervisorSaga() {
  yield put({ type: actionTypes.DICTIONARIES_SUPERVISOR_GET_START });

  const url = agentsEndpoints.GET_SUPERVISOR_DICTIONARIS;

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.DICTIONARIES_SUPERVISOR_GET_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.DICTIONARIES_SUPERVISOR_GET_FAILED,
      payload: { error },
    });
  }
}

export function* getDictionariesSupervisorRegionSaga(actions) {
  yield put({ type: actionTypes.DICTIONARIES_SUPERVISOR_REGION_START });

  const { RegionId } = actions.payload;
  const url = agentsEndpoints.GET_SUPERVISOR_DICTIONARIS_REGION;

  try {
    const { data } = yield axios.get(url, {
      params: {
        RegionId,
      },
    });

    yield put({
      type: actionTypes.DICTIONARIES_SUPERVISOR_REGION_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.DICTIONARIES_SUPERVISOR_REGION_FAILED,
      payload: { error },
    });
  }
}

import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/manager';
import * as endpoints from '@/const/api/managers';
import getLink from '@/utillity/routes';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';

export function* getManagerProfileSaga(action) {
  yield put({ type: actionTypes.GET_MANAGER_PROFILE.START });
  const { id } = action.payload;
  try {
    const { data } = yield axios.get(getLink(endpoints.GET_MANAGER_PROFILE, { id }));

    yield put({
      type: actionTypes.GET_MANAGER_PROFILE.SUCCESS,
      payload: { data },
    });

    yield put({
      type: actionTypes.GET_MANAGER_PROFILE.STOP,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_MANAGER_PROFILE.FAIL,
    });
    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export default getManagerProfileSaga;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'class-names';
import { Link } from 'react-router-dom';
import { ROUTE_CABINET } from '@/const/routes';
import UserIcon from '@/assets/images/ic-username.svg';
import NotificationIcon from '@/assets/images/ic-approve-notiffication.svg';
import { getApprovalsCounts } from '@/store/actions';
import CheckAccessHoc from '@/hoc/checkPermitions';
import { ROLE_BTL_MANAGERS, ROLE_DTC_MANAGERS } from '@/const/roleUserRole';
import './notification.scss';

const Notification = ({ user, approvalsCounts, getCounts }) => {
  if (!user) {
    return null;
  }

  const isDTCManager = user.Role === ROLE_DTC_MANAGERS;
  const isBTLManager = user.Role === ROLE_BTL_MANAGERS;

  useEffect(() => {
    if (isDTCManager || isBTLManager) {
      getCounts();
    }
  }, []);

  return (
    <CheckAccessHoc rights="view-total-approvals-count">
      <Link style={{ display: 'flex' }} to={ROUTE_CABINET}>
        <div className="User">
          <img src={UserIcon} alt="User" className="User_Icon" />
          <span className="User_Name">{user.Fullname}</span>
          <span className="User_Position">{user.Role || 'No role'}</span>
        </div>

        <div
          className={classNames('Notification', {
            Notification_counter: approvalsCounts && approvalsCounts.Total,
          })}
          data-count={approvalsCounts.Total}
        >
          <img src={NotificationIcon} alt="" />
        </div>
      </Link>
    </CheckAccessHoc>
  );
};

Notification.propTypes = {
  approvalsCounts: PropTypes.object.isRequired,
  getCounts: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = ({ auth, approvals }) => {
  return {
    user: auth.user,
    approvalsCounts: approvals.approvalsCounts,
  };
};

const mapDispatchToProps = dispatch => {
  return { getCounts: () => dispatch(getApprovalsCounts()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

import * as actionTypes from '@/store/actions/types/countries';

const initialState = {
  data: [],
  error: null,
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COUNTRIES.START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_COUNTRIES.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case actionTypes.GET_COUNTRIES.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;

/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import classNames from 'class-names';
import { Formik } from 'formik';
import { isObject } from 'lodash-es';
import Select from '../components/Select';
import SelectRange from '../components/SelectRange';
import RadioGroup from '../components/RadioGroup';
import Spinner from '@/components/parts/Spinner';
import { connect } from 'react-redux';
import { selectFormatter } from '@/utillity/general';
import setYupValidationSchema from '@/utillity/validationSchema/validationSchema';
import {
  rankData,
  clothSizes,
  shoeSize,
  heightOptions,
  genderData,
} from '@/components/forms/EmployeeForm/helpers';
import './filter-form.scss';

class FilterForm extends Component {
  submitHandler = values => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  getValue = (options, value) => {
    if (!options || value === null) return null;
    const findValue = options.find(el => el.value.toString() == value.toString());
    return findValue;
  };

  getCountryName = shortName => {
    switch (shortName) {
      case 'AR':
        return 'Armenia';
      case 'AZ':
        return 'Azerbaijan';
      case 'BY':
        return 'Belarus';
      default:
        return 'Georgia';
    }
  };

  selectCountriesFormatter = data => {
    if (!Array.isArray(data)) return [];

    return data.map(row => ({
      value: row.CountryId || row.сountryId,
      label: this.getCountryName(row.CountryName),
    }));
  };

  render() {
    const { onClose, handleReset, filtersData, dictionaries, loading, role } = this.props;
    const isDTC = role === 'DTC manager';
    return loading ? (
      <Spinner />
    ) : (
      <Formik
        initialValues={filtersData}
        validationSchema={setYupValidationSchema('FilterFormSchema')}
        onSubmit={this.submitHandler}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isValid,
          resetForm,
        }) => {
          const countriesOpt = this.selectCountriesFormatter(dictionaries.countries);
          let regionsOpt = selectFormatter(dictionaries.regions, 'CountryId');
          let citiesOpt = selectFormatter(dictionaries.cities, 'CountryId');
          const categoriesOpt = selectFormatter(dictionaries.categories);

          if (isDTC) {
            if (values.CountryId !== null) {
              regionsOpt = regionsOpt.filter(region => region.CountryId == values.CountryId);
              citiesOpt = citiesOpt.filter(city => city.CountryId == values.CountryId);
            }
          }

          const CountryIdValue = this.getValue(countriesOpt, values.CountryId);
          const RegionIdValue = this.getValue(regionsOpt, values.regionId);
          const CityIdValue = this.getValue(citiesOpt, values.cityId);

          const handleFieldValue = name => val => {
            if (isObject(val)) {
              setFieldValue(name, val.value);
            } else {
              setFieldValue(name, val);
            }
          };

          const handleFieldTouched = name => () => {
            setFieldTouched(name, true);
          };

          const handleResetForm = () => {
            handleReset();
            resetForm();
          };

          const handleCountryValue = name => val => {
            const selectedRegion = dictionaries.regions.find(
              region => region.Id == values.regionId,
            );
            const selectedCity = dictionaries.cities.find(city => city.Id == values.cityId);
            if (typeof selectedRegion !== 'undefined') {
              if (selectedRegion.CountryId != val.value) {
                setFieldValue('regionId', null);
              }
            }
            if (typeof selectedCity !== 'undefined') {
              if (selectedCity.CountryId != val.value) {
                setFieldValue('cityId', null);
              }
            }

            if (isObject(val)) {
              setFieldValue(name, val.value);
            } else {
              setFieldValue(name, val);
            }
          };

          return (
            <form onSubmit={handleSubmit} className="FilterForm">
              <div className="FilterForm_Groups">
                <div className="FilterForm_Group">
                  {isDTC ? (
                    <Select
                      value={CountryIdValue}
                      options={countriesOpt}
                      name="countryId"
                      label="Country"
                      placeholder="Select a country"
                      onChange={handleCountryValue('CountryId')}
                      onBlur={handleFieldTouched('CountryId')}
                      error={touched.CountryId && errors.CountryId}
                    />
                  ) : (
                    <Select
                      value={CountryIdValue}
                      options={countriesOpt}
                      name="countryId"
                      label="Country"
                      placeholder="Select a country"
                      disabled
                      onChange={handleFieldValue('CountryId')}
                      onBlur={handleFieldTouched('CountryId')}
                      error={touched.CountryId && errors.CountryId}
                    />
                  )}
                  <Select
                    value={RegionIdValue}
                    options={regionsOpt}
                    name="regionId"
                    label="Region"
                    disabled={role === 'BTL supervisor'}
                    placeholder="Select a region"
                    onChange={handleFieldValue('regionId')}
                    onBlur={handleFieldTouched('regionId')}
                    error={touched.regionId && errors.regionId}
                  />
                  <Select
                    value={CityIdValue}
                    options={citiesOpt}
                    name="city"
                    label="City"
                    placeholder="Select a city"
                    onChange={handleFieldValue('cityId')}
                    onBlur={handleFieldTouched('cityId')}
                    error={touched.cityId && errors.cityId}
                  />
                </div>
                <div className="FilterForm_Group">
                  <Select
                    name="hostess"
                    value={this.getValue(rankData, values.hostess)}
                    options={rankData}
                    label="Consultant’s rank"
                    placeholder="Select a rank"
                    onChange={handleFieldValue('hostess')}
                    onBlur={handleFieldTouched('hostess')}
                    error={touched.hostess && errors.hostess}
                  />
                  <Select
                    name="status"
                    value={this.getValue(categoriesOpt, values.status)}
                    options={categoriesOpt}
                    label="Consultant’s status"
                    placeholder="Select a status"
                    onChange={handleFieldValue('status')}
                    onBlur={handleFieldTouched('status')}
                    error={touched.status && errors.status}
                  />
                  <SelectRange
                    name="height"
                    values={values}
                    options={heightOptions}
                    label="Height, cm"
                    onChange={handleFieldValue}
                    onBlur={handleFieldTouched}
                    error={
                      (touched.height_from || touched.height_to) &&
                      (errors.height_from || errors.height_to)
                    }
                  />
                </div>
                <div className="FilterForm_Group">
                  <SelectRange
                    name="cloth_size"
                    values={values}
                    options={clothSizes}
                    label="Cloth size"
                    onChange={handleFieldValue}
                    onBlur={handleFieldTouched}
                    error={
                      (touched.cloth_size_from || touched.cloth_size_to) &&
                      (errors.cloth_size_from || errors.cloth_size_to)
                    }
                  />
                  <SelectRange
                    name="shoe_size"
                    values={values}
                    options={shoeSize}
                    label="Shoe size"
                    onChange={handleFieldValue}
                    onBlur={handleFieldTouched}
                    error={
                      (touched.shoe_size_from || touched.shoe_size_to) &&
                      (errors.shoe_size_from || errors.shoe_size_to)
                    }
                  />

                  <RadioGroup
                    name="gender"
                    value={values.gender}
                    data={genderData}
                    label="Gender"
                    onChange={handleFieldValue('gender')}
                    onBlur={handleFieldTouched('gender')}
                    error={touched.gender && errors.gender}
                  />
                </div>
              </div>
              <div className="FilterForm_Actions Actions">
                <div className="Actions_Group">
                  <p className="FilterForm_clear" onClick={handleResetForm}>
                    Clear filters
                  </p>
                </div>
                <div className="Actions_Group">
                  <button
                    className="FilterForm_Button FilterForm_cancel"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    className={classNames('FilterForm_Button FilterForm_submit', {
                      FilterForm_disabled: !isValid,
                    })}
                    type="submit"
                    disabled={!isValid}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
}
const mapDispatchToProps = ({ auth }) => ({
  role: auth.user && auth.user.Role,
});

export default connect(mapDispatchToProps, null)(FilterForm);

import * as actionTypes from './types/popups';

export const popupAddedSuccess = state => {
  return {
    type: actionTypes.POPUPS_ADDED_SUCCESS,
    payload: {
      state,
    },
  };
};

export const popupAlbum = (type, state) => {
  return {
    type: actionTypes.POPUPS_ALBUM_SUCCESS,
    payload: {
      type,
      state,
    },
  };
};

export const popupApprovalRequest = (state, type) => {
  return {
    type: actionTypes.POPUPS_APPROVAL_REQUEST,
    payload: {
      state,
      type,
    },
  };
};

export default popupAddedSuccess;

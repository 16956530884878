import * as actionTypes from '@/store/actions/types/files';

export const exportAgents = params => {
  return {
    type: actionTypes.EXPORT_AGENTS,
    payload: { params },
  };
};

export const downloadFile = data => {
  return {
    type: actionTypes.DOWNLOAD_FILE,
    payload: { data },
  };
};

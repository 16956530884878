/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import './notification-success.scss';
import close from '../../../assets/images/close.svg';
import { clearNotificationAction } from '../../../store/actions/notification';

function NotificationSuccess(props) {
  // consoles.log("props.notificationText hrererererer", props);
  if (props.notificationsText) {
    return (
      <div
        role="presentation"
        className="notification-success"
        onClick={props.clearNotificationAction}
      >
        {props.notificationsText}
        <button type="button">
          <img src={close} alt="" />
        </button>
      </div>
    );
  }
  return null;
}

const mapStateToProps = state => ({
  notificationsText: state.notifications.notificationText,
});

export default connect(mapStateToProps, { clearNotificationAction })(NotificationSuccess);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

const Calendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <path fillRule="nonzero" d="M-3-3h24v24H-3z" />
      <path
        fill="#8A97A0"
        fillRule="nonzero"
        d="M0 2.972v12.056C0 16.663 1.386 18 3.08 18h11.84c1.694 0 3.08-1.337 3.08-2.972V2.972C18 1.337 16.614 0 14.92 0H3.08C1.387 0 0 1.337 0 2.972zm14.92 13.754H3.08c-.968 0-1.76-.764-1.76-1.698V6.856h15.36v8.172c0 .934-.792 1.698-1.76 1.698zM3.08 1.274h11.84c.968 0 1.76.764 1.76 1.698v2.59H1.32v-2.59c0-.934.792-1.698 1.76-1.698z"
      />
      <path
        fill="#8A97A0"
        d="M5.25 7.5c.425 0 .75-.325.75-.75v-1.5a.737.737 0 0 0-.75-.75.737.737 0 0 0-.75.75v1.5c0 .425.325.75.75.75zM12.75 7.5c.425 0 .75-.325.75-.75v-1.5a.737.737 0 0 0-.75-.75.737.737 0 0 0-.75.75v1.5c0 .425.325.75.75.75z"
      />
    </g>
  </svg>
);

export default Calendar;

import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

// COMMON
import Layout from '@/components/Layout';
import PublicRoute from './PublicRoute';
import PrivetRoute from './PrivateRoute';
import Spinner from '../../parts/Spinner';

const loader = <Spinner />;

const layoutRouter = props => {
  const { component: Component, isAuth, protection, ...rest } = props;
  let Structure = params => (
    <Suspense fallback={loader}>
      <Component {...params} />
    </Suspense>
  );
  if (isAuth) {
    Structure = params => (
      <Suspense fallback={loader}>
        <Layout>
          <Component {...params} />
        </Layout>
      </Suspense>
    );
  }

  return protection ? (
    <PrivetRoute {...rest} isAuth={isAuth} component={Structure} />
  ) : (
    <PublicRoute {...rest} isAuth={isAuth} component={Structure} />
  );
};

layoutRouter.defaultProps = {
  protection: false,
  isAuth: false,
};

layoutRouter.propTypes = {
  role: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  authPicture: PropTypes.node,
  protection: PropTypes.bool,
  isAuth: PropTypes.bool,
  component: PropTypes.object,
};

export default layoutRouter;

// DICTIONARIES actions
export const DICTIONARIES_GET = 'DICTIONARIES_GET';
export const DICTIONARIES_GET_START = 'DICTIONARIES_GET_START';
export const DICTIONARIES_GET_SUCCESS = 'DICTIONARIES_GET_SUCCESS';
export const DICTIONARIES_GET_FAILED = 'DICTIONARIES_GET_FAILED';

export const DICTIONARIES_SUPERVISOR_GET = 'DICTIONARIES_SUPERVISOR_GET';
export const DICTIONARIES_SUPERVISOR_GET_START = 'DICTIONARIES_SUPERVISOR_GET_START';
export const DICTIONARIES_SUPERVISOR_GET_SUCCESS = 'DICTIONARIES_SUPERVISOR_GET_SUCCESS';
export const DICTIONARIES_SUPERVISOR_GET_FAILED = 'DICTIONARIES_SUPERVISOR_GET_FAILED';

export const DICTIONARIES_SUPERVISOR_REGION = 'DICTIONARIES_SUPERVISOR_REGION';
export const DICTIONARIES_SUPERVISOR_REGION_START = 'DICTIONARIES_SUPERVISOR_REGION_START';
export const DICTIONARIES_SUPERVISOR_REGION_SUCCESS = 'DICTIONARIES_SUPERVISOR_REGION_SUCCESS';
export const DICTIONARIES_SUPERVISOR_REGION_FAILED = 'DICTIONARIES_SUPERVISOR_REGION_FAILED';

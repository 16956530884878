import * as actionTypes from '@/store/actions/types/managers';

const initialState = {
  list: [],
  error: null,
  loading: false,
  total_pages: 1,
  current_page: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MANAGERS.START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_MANAGERS.SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        total_pages: action.payload.meta.last_page,
        current_page: action.payload.meta.current_page,
      };
    case actionTypes.GET_MANAGERS.FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;

/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import * as routePaths from '@/const/routes';
import getLink from '@/utillity/routes';
import CheckAccessHoc from '@/hoc/checkPermitions';
import * as actions from '@/store/actions';
import logoutIcon from '@/assets/images/logout.png';
import { Managers, Consultants, Archive, Testing, Materials, Cities } from '@/icons';
import './main-menu.scss';

const MainMenu = props => (
  <div className="MainMenu">
    <CheckAccessHoc rights="btlsupervisors-view">
      <NavLink
        to={getLink(routePaths.ROUTE_MANAGERS_LIST, { type: 'btl-supervisors' })}
        className="MainMenu_Item MenuItem"
        activeClassName="MenuItem-isActive"
      >
        <div className="MenuItem_Image">
          <Managers color="#8a97a0" width="100%" height="100%" />
        </div>
        <span className="MenuItem_Text">Managers</span>
      </NavLink>
    </CheckAccessHoc>
    <CheckAccessHoc rights="view-agents">
      <NavLink
        to={routePaths.ROUTE_CONSULTANTS}
        className="MainMenu_Item MenuItem"
        activeClassName="MenuItem-isActive"
      >
        <div className="MenuItem_Image">
          <Consultants color="#8a97a0" width="100%" height="100%" />
        </div>
        <span className="MenuItem_Text">BTL Consultants</span>
      </NavLink>
    </CheckAccessHoc>
    <NavLink
      to={getLink(routePaths.ROUTE_PHOTO_ARCHIVE, { type: 'archive' })}
      className="MainMenu_Item MenuItem"
      activeClassName="MenuItem-isActive"
    >
      <div className="MenuItem_Image">
        <Archive color="#8a97a0" width="100%" height="100%" />
      </div>
      <span className="MenuItem_Text">Photo Archive</span>
    </NavLink>
    <NavLink
      to={routePaths.ROUTE_TESTING}
      className="MainMenu_Item MenuItem"
      activeClassName="MenuItem-isActive"
    >
      <div className="MenuItem_Image">
        <Testing color="#8a97a0" width="100%" height="100%" />
      </div>
      <span className="MenuItem_Text">Online Testing</span>
    </NavLink>
    <NavLink
      to={routePaths.ROUTE_MATERIALS}
      className="MainMenu_Item MenuItem"
      activeClassName="MenuItem-isActive"
    >
      <div className="MenuItem_Image">
        <Materials color="#8a97a0" width="100%" height="100%" />
      </div>
      <span className="MenuItem_Text">Materials</span>
    </NavLink>
    <NavLink
      to={routePaths.ROUTE_REGIONS}
      className="MainMenu_Item MenuItem"
      activeClassName="MenuItem-isActive"
    >
      <div className="MenuItem_Image">
        <Cities color="#8a97a0" width="100%" height="100%" />
      </div>
      <span className="MenuItem_Text">Regions</span>
    </NavLink>
    <div role="presentation" className="MainMenu_Item MenuItem" onClick={props.logout}>
      <div className="MenuItem_Image">
        <img src={logoutIcon} alt="" style={{ width: '100%' }} />
      </div>
      <span className="MenuItem_Text">Log Out</span>
    </div>
  </div>
);

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(actions.logout()),
});

export default connect(null, mapDispatchToProps)(MainMenu);

import * as Yup from 'yup';
import { checkLangLength, checkLangLevel, phoneValidate } from '@/utillity/validationSchema/helper';

export default () => {
  return {
    AgentAvatar: Yup.object()
      .shape({
        Id: Yup.mixed().nullable(),
        Type: Yup.string(),
        Url: Yup.mixed().required('Avatar is required'),
      })
      .required('Avatar is required'),
    AgentRank: Yup.object().required('Rank is required'),
    AgentStatus: Yup.object().required('Status is required'),
    AgentName: Yup.string().required('Name is required'),
    AgentSurname: Yup.string().required('Surname is required'),
    AgentBirthdate: Yup.string()
      .nullable()
      .required('Birthdate is required'),
    AgentGender: Yup.string().required('Gender is required'),
    AgentPassport: Yup.string()
      .required('Passport is required')
      .max(11, 'max 11 characters'),
    ActiveCity: Yup.object().required('City is required'),
    ActiveRegion: Yup.object().required('Region is required'),
    AgentChest: Yup.number()
      .required('Body parametrs is required')
      .positive('must be a positive number'),
    AgentWaist: Yup.number()
      .required('Body parametrs is required')
      .positive('must be a positive number'),
    AgentHips: Yup.number()
      .required('Body parametrs is required')
      .positive('must be a positive number'),
    AgentMobilePhone: phoneValidate('AgentMobilePhone'),
    AgentEmailAddress: Yup.string()
      .email('Email is not valid')
      .required('Email adress is required'),
    Languages: Yup.array()
      .test('is-one-valid', 'Languages is required', checkLangLength)
      .test('is-one-valid', 'level is required', checkLangLevel),
    AgentHeight: Yup.number()
      .min(100, 'Height should be more than ${min}')
      .max(250, 'Height should be less than ${max}')
      .required('Height is required'),
    AgentWeight: Yup.number()
      .required('Weight is required')
      .positive('must be a positive number'),
    AgentClothingSize: Yup.object().required('Clothing size is required'),
    AgentShoeSize: Yup.object().required('Shoe size is required'),
    AgentPhoto: Yup.object()
      .shape({
        Id: Yup.mixed().nullable(),
        Type: Yup.string(),
        Url: Yup.mixed().required('Full height photo  is required'),
      })
      .required('Full height photo  is required'),
    AgentDocs: Yup.object().shape({
      Id: Yup.mixed().nullable(),
      Type: Yup.string(),
      Url: Yup.mixed(),
    }),
    AdditionalInformation: Yup.string(),
    WorkExperience: Yup.string(),
    Recommendations: Yup.string(),
    Trainings: Yup.array(),
  };
};

import * as actionTypes from '@/store/actions/types/regions';
import { put } from 'redux-saga/effects';
import * as regionsEndpoints from '@/const/api/regions';
import axios from 'axios';

export function* getRegionsListSaga(action) {
  yield put({ type: actionTypes.REGIONS_GET_LIST_START });

  const { params } = action.payload;
  const url = regionsEndpoints.GET_REGIONS;

  try {
    const { data } = yield axios.get(url, { params });

    yield put({
      type: actionTypes.REGIONS_GET_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.REGIONS_GET_LIST_FAILED,
      payload: { error },
    });
  }
}

export default getRegionsListSaga;

import * as actionTypes from './types/tests';
import { createAction } from '@/utillity/createAction';

export const getTestsInstructions = type => {
  return createAction(actionTypes.GET_TESTS_INSTRUCTIONS.INIT, { type });
};

export const getTestsResults = (type, params = {}) => {
  return createAction(actionTypes.GET_TESTS_RESULTS.INIT, { type, params });
};

export const getTestDetail = (params = {}) => {
  return createAction(actionTypes.GET_TEST_DETAIL.INIT, { params });
};

export const getTestsPassList = (type, params) => {
  return createAction(actionTypes.GET_TESTS_PASS_LIST.INIT, { type, params });
};

export const exportTestsPassList = type => {
  return createAction(actionTypes.EXPORT_TESTS_PASS_LIST.INIT, { type });
};

export const getTestsAllList = params => {
  return createAction(actionTypes.GET_TESTS_ALL_LIST.INIT, { params });
};

export const getTestHistoryList = (type, params) => {
  return createAction(actionTypes.GET_TEST_HISTORY_LIST.INIT, { type, params });
};

export default getTestsInstructions;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Efficient = props => {
  const { color, width, height, withBorder, isActive, activeColor } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#12ABAE" />
          <stop offset="100%" stopColor="#88C69A" />
        </linearGradient>
      </defs>
      {withBorder && (
        <rect
          width="39"
          height="39"
          x=".5"
          y=".5"
          rx="2"
          stroke={isActive ? activeColor : color}
          fill={isActive ? color : 'none'}
        />
      )}
      <g fill={isActive ? activeColor : color}>
        <path
          className="Hover"
          d="M27.778 9H30v22.222h-2.222zM23.333 21.222h2.222v10h-2.222zM18.889 16.778h2.222v14.444h-2.222zM14.444 20.111h2.222v11.111h-2.222zM10 13.444h2.222v17.778H10z"
        />
      </g>
    </svg>
  );
};

Efficient.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  withBorder: PropTypes.bool,
};

Efficient.defaultProps = {
  color: 'url(#gradient)',
  width: '40px',
  height: '40px',
  withBorder: true,
};

export default Efficient;

// -- Endpoints --

// Tests
export const TESTS_INSTRUCTIONS = '/api/online-test/:type/instructions';
export const TESTS_RESULTS = '/api/online-test/:type/results';
export const TESTS_PASS_LIST = '/api/online-test/:type/list';
export const TESTS_EXPORT_PASS_LIST = '/api/online-test/:type/export';

export const TESTS_ALL_LIST = '/api/online-test/list';
export const TEST_DETAIL = 'api/online-test/result';
export const TEST_HISTORY = 'api/online-test/:type/results';
export default TESTS_INSTRUCTIONS;

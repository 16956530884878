import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import axios from 'axios';
import configureStore from './store/configureStore';
import Root from './components/Root';
import history from './utillity/history';
import httpService from './utillity/httpService';
import './App.scss';
import ErrorBoundary from './components/parts/ErrorBoundary';
import 'antd/lib/notification/style/index.css';
import 'antd/lib/modal/style/index.css';
import 'antd/lib/select/style/index.css';

axios.defaults.baseURL = process.env.REACT_APP_BASE;
// axios.defaults.baseURL = 'http://192.168.89.105/pvs/public/';
// axios.defaults.baseURL = "https://testcrmcaucasus.adbakers.com.ua";

const store = configureStore();

// auth Interceptor
httpService.setupInterceptors(store, history);

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <div className="JTI_PWS_APP">
          <ErrorBoundary>
            <Root />
          </ErrorBoundary>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

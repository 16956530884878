import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/approval';
import * as endpoints from '@/const/api/approvals';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import { POPUPS_APPROVAL_REQUEST } from '../actions/types/popups';
import getLink from '@/utillity/routes';
import { getApprovalsCountsSaga } from './approvals';

export function* sendApprovalSaga(action) {
  yield put({ type: actionTypes.SEND_APPROVAL.START });
  const { ApprovalId, type, userType, callBack } = action.payload;
  try {
    yield axios.post(getLink(endpoints.APPROVAL_SEND, { type, userType }), {
      ApprovalId,
    });
    yield put({
      type: actionTypes.SEND_APPROVAL.SUCCESS,
    });
    if (callBack) {
      yield call(callBack);
      yield call(getApprovalsCountsSaga);
    }
    yield call(openNotificationWithIcon, {
      type: 'success',
      message:
        type === 'approve' || type === 'approve-category'
          ? 'has been approved'
          : 'has been rejected',
    });
  } catch (error) {
    yield put({
      type: actionTypes.SEND_APPROVAL.FAIL,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  } finally {
    yield put({
      type: actionTypes.SEND_APPROVAL.STOP,
    });
  }
  yield put({
    type: POPUPS_APPROVAL_REQUEST,
    payload: { type: '', state: false },
  });
}

export default sendApprovalSaga;

import * as actionTypes from '@/store/actions/types/agents';
import * as approvalsActionTypes from '@/store/actions/types/approvals';

const initialState = {
  list: [],
  currentPage: 0,
  totalPages: 0,
  editData: null,
  loadings: {
    list: true,
    editData: true,
  },
  errors: {
    list: null,
    editData: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AGENTS_GET_LIST_START:
      return {
        ...state,
        list: [],
        currentPage: 0,
        totalPages: 0,
        loadings: {
          ...state.loadings,
          list: true,
        },
        errors: {
          ...state.errors,
          list: null,
        },
      };
    case actionTypes.AGENTS_GET_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.data.data,
        currentPage: action.payload.data.meta.current_page,
        totalPages: action.payload.data.meta.last_page,
        loadings: {
          ...state.loadings,
          list: false,
        },
      };
    case actionTypes.AGENTS_GET_LIST_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          list: false,
        },
        errors: {
          ...state.errors,
          list: action.payload.error,
        },
      };

    case actionTypes.AGENTS_GET_LAZY_LIST_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          list: true,
        },
        errors: {
          ...state.errors,
          list: null,
        },
      };
    case actionTypes.AGENTS_GET_LAZY_LIST_SUCCESS:
      return {
        ...state,
        list: state.list.concat(action.payload.data.data),
        currentPage: action.payload.data.meta.current_page,
        totalPages: action.payload.data.meta.last_page,
      };
    case actionTypes.AGENTS_GET_LAZY_LIST_STOP:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          list: false,
        },
      };
    case actionTypes.AGENTS_GET_LAZY_LIST_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          list: false,
        },
        errors: {
          ...state.errors,
          list: action.payload.error,
        },
      };

    case actionTypes.AGENTS_CLEAR_LIST:
      return {
        ...state,
        list: initialState.list,
        currentPage: initialState.currentPage,
        totalPages: initialState.totalPages,
        loadings: {
          ...state.loadings,
          list: initialState.loadings.list,
        },
        errors: {
          ...state.errors,
          list: initialState.errors.list,
        },
      };

    case actionTypes.AGENT_GET_INFO_EDIT_START:
      return {
        ...state,
        editData: null,
        loadings: {
          ...state.loadings,
          editData: true,
        },
        errors: {
          ...state.errors,
          editData: null,
        },
      };
    case actionTypes.AGENT_GET_INFO_EDIT_SUCCESS:
      return {
        ...state,
        editData: action.payload.data,
        loadings: {
          ...state.loadings,
          editData: false,
        },
      };
    case actionTypes.AGENT_GET_INFO_EDIT_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          editData: false,
        },
        errors: {
          ...state.errors,
          editData: action.payload.error,
        },
      };

    case actionTypes.AGENT_UPDATE_INFO_EDIT_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          editData: true,
        },
        errors: {
          ...state.errors,
          editData: null,
        },
      };
    case actionTypes.AGENT_UPDATE_INFO_EDIT_SUCCESS:
      return {
        ...state,
        editData: { ...state.editData, agent: action.payload.agent },
        loadings: {
          ...state.loadings,
          editData: false,
        },
      };
    case actionTypes.AGENT_CREATE_INFO_SUCCESS:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          editData: false,
        },
      };
    case actionTypes.AGENT_UPDATE_INFO_EDIT_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          editData: false,
        },
        errors: {
          ...state.errors,
          editData: action.payload.error,
        },
      };

    case approvalsActionTypes.APPROVALS_REQUEST_CHANGES_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          editData: true,
        },
        errors: {
          ...state.errors,
          editData: null,
        },
      };
    case approvalsActionTypes.APPROVALS_REQUEST_CHANGES_SUCCESS:
      return {
        ...state,
        editData: {
          ...state.editData,
          agent: {
            ...state.editData.agent,
            Approvals: {
              ...state.editData.agent.Approvals,
              [action.payload.type]: {
                ...state.editData.agent.Approvals[action.payload.type],
                DTCRejected: false,
              },
            },
          },
        },
        loadings: {
          ...state.loadings,
          editData: false,
        },
      };
    case approvalsActionTypes.APPROVALS_REQUEST_CHANGES_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          editData: false,
        },
        errors: {
          ...state.errors,
          editData: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

// AGENTS actions
export const AGENTS_GET_LIST = 'AGENTS_GET_LIST';
export const AGENTS_GET_LIST_START = 'AGENTS_GET_LIST_START';
export const AGENTS_GET_LIST_SUCCESS = 'AGENTS_GET_LIST_SUCCESS';
export const AGENTS_GET_LIST_FAILED = 'AGENTS_GET_LIST_FAILED';

export const AGENTS_GET_LAZY_LIST = 'AGENTS_GET_LAZY_LIST';
export const AGENTS_GET_LAZY_LIST_START = 'AGENTS_GET_LAZY_LIST_START';
export const AGENTS_GET_LAZY_LIST_SUCCESS = 'AGENTS_GET_LAZY_LIST_SUCCESS';
export const AGENTS_GET_LAZY_LIST_STOP = 'AGENTS_GET_LAZY_LIST_STOP';
export const AGENTS_GET_LAZY_LIST_FAILED = 'AGENTS_GET_LAZY_LIST_FAILED';

export const AGENTS_CLEAR_LIST = 'AGENTS_CLEAR_LIST';

export const AGENT_GET_INFO_EDIT = 'AGENT_GET_INFO_EDIT';
export const AGENT_GET_INFO_EDIT_START = 'AGENT_GET_INFO_EDIT_START';
export const AGENT_GET_INFO_EDIT_SUCCESS = 'AGENT_GET_INFO_EDIT_SUCCESS';
export const AGENT_GET_INFO_EDIT_FAILED = 'AGENT_GET_INFO_EDIT_FAILED';

export const AGENT_CREATE = 'AGENT_CREATE';

export const AGENT_UPDATE_INFO_EDIT = 'AGENT_UPDATE_INFO_EDIT';
export const AGENT_UPDATE_INFO_EDIT_START = 'AGENT_UPDATE_INFO_EDIT_START';
export const AGENT_UPDATE_INFO_EDIT_SUCCESS = 'AGENT_UPDATE_INFO_EDIT_SUCCESS';
export const AGENT_CREATE_INFO_SUCCESS = 'AGENT_CREATE_INFO_SUCCESS';
export const AGENT_UPDATE_INFO_EDIT_FAILED = 'AGENT_UPDATE_INFO_EDIT_FAILED';

import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/approvals';
import * as endpoints from '@/const/api/approvals';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import { POPUPS_APPROVAL_REQUEST } from '../actions/types/popups';

export function* getApprovalsCountsSaga() {
  yield put({ type: actionTypes.APPROVALS_COUNTS.START });

  try {
    const { data } = yield axios.get(endpoints.GET_APPROVALS_COUNT);

    yield put({
      type: actionTypes.APPROVALS_COUNTS.SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_APPROVALS.FAIL,
      payload: { error },
    });
  }
}

export function* getApprovalsListSaga(action) {
  yield put({ type: actionTypes.GET_APPROVALS.START });
  const { type, ...restParams } = action.payload;

  try {
    const {
      data: { data, meta },
    } = yield axios.get(endpoints.GET_APPROVALS_LIST.replace(':type', type), {
      params: { ...restParams },
    });
    yield call(getApprovalsCountsSaga);

    yield put({
      type: actionTypes.GET_APPROVALS.SUCCESS,
      payload: { data, meta },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_APPROVALS.FAIL,
      payload: { error },
    });
  }
}

export function* requestApprovalChangesSaga(action) {
  yield put({ type: actionTypes.APPROVALS_REQUEST_CHANGES_START });
  const { id, type, user } = action.payload;

  try {
    if (user !== 'manager') {
      yield axios.post(endpoints.APPROVALS_RESEND, { ApprovalId: id });
    } else {
      yield axios.post(endpoints.APPROVALS_DTC_SEND, { BTLSupervisorId: +id });
    }

    yield put({
      type: actionTypes.APPROVALS_REQUEST_CHANGES_SUCCESS,
      payload: { type },
    });

    yield call(openNotificationWithIcon, {
      type: 'success',
      message: 'Successfully',
      description: `${type} request changes has been requested`,
    });
  } catch (error) {
    yield put({
      type: actionTypes.APPROVALS_REQUEST_CHANGES_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
  yield put({
    type: POPUPS_APPROVAL_REQUEST,
    payload: { type: '', state: false },
  });
}

export default getApprovalsListSaga;

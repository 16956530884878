import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/tests';
import * as endpoints from '@/const/api/tests';
import getLink from '@/utillity/routes';

export function* getTestsInstructionsSaga(action) {
  yield put({ type: actionTypes.GET_TESTS_INSTRUCTIONS.START });

  const { type } = action.payload;
  try {
    const {
      data: { data },
    } = yield axios.get(getLink(endpoints.TESTS_INSTRUCTIONS, { type }));

    yield put({
      type: actionTypes.GET_TESTS_INSTRUCTIONS.SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TESTS_INSTRUCTIONS.FAIL,
      payload: { error },
    });
  }
}

export function* getTestsPassListSaga(action) {
  yield put({ type: actionTypes.GET_TESTS_PASS_LIST.START });

  const { type, params } = action.payload;
  try {
    const { data } = yield axios.get(getLink(endpoints.TESTS_PASS_LIST, { type }), { params });

    yield put({
      type: actionTypes.GET_TESTS_PASS_LIST.SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TESTS_PASS_LIST.FAIL,
      payload: { error },
    });
  }
}

export function* exportTestsPassListSaga(action) {
  yield put({ type: actionTypes.EXPORT_TESTS_PASS_LIST.START });

  const { type } = action.payload;
  const url = getLink(endpoints.TESTS_EXPORT_PASS_LIST, { type });
  try {
    yield axios({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
        encoding: 'UTF-8',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Passing tests restults [${type}].xlsx`;
      link.click();
    });

    yield put({
      type: actionTypes.EXPORT_TESTS_PASS_LIST.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: actionTypes.EXPORT_TESTS_PASS_LIST.FAIL,
      payload: { error },
    });
  }
}

export function* getTestsResultsSaga(action) {
  yield put({ type: actionTypes.GET_TESTS_RESULTS.START });

  const { type, params } = action.payload;
  const url = getLink(endpoints.TESTS_RESULTS, { type });

  try {
    const {
      data: { data, meta },
    } = yield axios.get(url, { params });

    yield put({
      type: actionTypes.GET_TESTS_RESULTS.SUCCESS,
      payload: { data, meta },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TESTS_RESULTS.FAIL,
      payload: { error },
    });
  }
}

export function* getTestsAllListSaga(action) {
  yield put({ type: actionTypes.GET_TESTS_ALL_LIST.START });

  const { params } = action.payload;

  try {
    const { data } = yield axios.get(endpoints.TESTS_ALL_LIST, { params });

    yield put({
      type: actionTypes.GET_TESTS_ALL_LIST.SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TESTS_ALL_LIST.FAIL,
      payload: { error },
    });
  }
}

export function* getTestDetailSaga(action) {
  yield put({ type: actionTypes.GET_TEST_DETAIL.START });

  const { params } = action.payload;

  try {
    const { data } = yield axios.get(endpoints.TEST_DETAIL, { params });

    yield put({
      type: actionTypes.GET_TEST_DETAIL.SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TEST_DETAIL.FAIL,
      payload: { error },
    });
  }
}

export function* getTestHistorySaga(action) {
  yield put({ type: actionTypes.GET_TEST_HISTORY_LIST.START });

  const { type, params } = action.payload;
  const url = getLink(endpoints.TEST_HISTORY, { type });

  try {
    const {
      data: { data, meta },
    } = yield axios.get(url, { params });

    yield put({
      type: actionTypes.GET_TEST_HISTORY_LIST.SUCCESS,
      payload: { data, meta },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TEST_HISTORY_LIST.FAIL,
      payload: { error },
    });
  }
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

const Download = props => (
  <svg viewBox="0 0 138 124" width={138} height={124} {...props}>
    <defs>
      <path
        d="M119.95 41.53s0-9.7-9.69-9.7H14.48s-9.69 0-9.69 9.7v51.84s0 9.7 9.69 9.7h59.27a32.393 32.393 0 01-3.52-14.7c0-17.82 14.3-32.26 31.94-32.26 6.58-.01 12.7 2.01 17.78 5.46V41.53z"
        id="prefix__a"
      />
      <path
        d="M119.95 26.2s0-9.69-9.69-9.69H54.62V14s0-9.69-9.7-9.69H24.57s-9.7 0-9.7 9.69v17.83h95.39c9.69 0 9.69 9.7 9.69 9.7V26.2z"
        id="prefix__b"
      />
      <path
        d="M134.1 88.36c0-17.82-14.29-32.26-31.93-32.26S70.23 70.54 70.23 88.36s14.3 32.26 31.94 32.26c17.63 0 31.93-14.44 31.93-32.26z"
        id="prefix__c"
      />
      <path
        d="M89.34 93.26c5.75 5.75 8.95 8.94 9.59 9.58 0 0 3.24 3.24 6.48 0l9.58-9.58"
        id="prefix__d"
      />
      <path d="M102.17 69.61v34.67" id="prefix__e" />
    </defs>
    <use xlinkHref="#prefix__a" fillOpacity={0} />
    <use xlinkHref="#prefix__a" fillOpacity={0} stroke="#fff" strokeWidth={7} />
    <use xlinkHref="#prefix__b" fillOpacity={0} />
    <use xlinkHref="#prefix__b" fillOpacity={0} stroke="#fff" strokeWidth={7} />
    <use xlinkHref="#prefix__c" fillOpacity={0} />
    <use xlinkHref="#prefix__c" fillOpacity={0} stroke="#fff" strokeWidth={7} />
    <g>
      <use xlinkHref="#prefix__d" fillOpacity={0} />
      <use xlinkHref="#prefix__d" fillOpacity={0} stroke="#fff" strokeWidth={7} />
    </g>
    <g>
      <use xlinkHref="#prefix__e" fillOpacity={0} />
      <use xlinkHref="#prefix__e" fillOpacity={0} stroke="#fff" strokeWidth={7} />
    </g>
  </svg>
);

export default Download;

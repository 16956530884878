/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

const Add = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g fill="none" fillRule="evenodd">
      <g>
        <rect
          width="20.5"
          height="20.5"
          x=".75"
          y=".75"
          stroke="#FFF"
          strokeWidth="1.5"
          rx="10.25"
        />
        <path fill="#FFF" d="M10.358 5.5h1.375v11h-1.375z" />
        <path fill="#FFF" d="M5.546 11.687v-1.375h11v1.375z" />
      </g>
    </g>
  </svg>
);

export default Add;

// -- Endpoints --

// PhotoArchive
export const GET_ARCHIVES = '/api/archives';
export const GET_ACTIVITY = '/api/activities';
export const GET_SUPERVISORS = '/api/activities/user';
export const GET_YEARS = '/api/activities/year';
export const GET_SLIDER = '/api/archives/slider/:id';
export const GET_DETAIL_ALBUM = '/api/archives/:id';
export const CREATE_ALBUM = '/api/archives';
export const GET_OUTLET = '/api/outlets';
export const DELETE_PHOTO = 'api/archives/photos';
export const DOWNLOAD_ALBUM = 'api/archives/albums/download';
export const DOWNLOAD_PHOTO = 'api/archives/photos/download';
export const ADD_PHOTO_TO_ALBUM = 'api/archives/:id';

const getErrorMessage = error => {
  let errorMessage = '';
  if (error.response && error.response.data.errors) {
    const handledErrors = error.response.data.errors;
    const errorKeys = Object.keys(handledErrors);

    errorMessage = handledErrors[errorKeys[0]];
  } else if (error.response && error.response.data.error) {
    errorMessage = error.response.data.error;
  } else if (error.response && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else {
    errorMessage = error.message;
  }

  return errorMessage;
};

export default getErrorMessage;

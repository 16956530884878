/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import ReactSelect from 'react-select';
import ReactTooltip from 'react-tooltip';
import classNames from 'class-names';
import infoIcon from '@/assets/images/ic-info-hover.svg';
import './select.scss';

const Select = ({
  className,
  error,
  onChange,
  touched,
  onBlur,
  label,
  type,
  disabled,
  name,
  value,
  options,
  showTooltip,
  tooltipContent,
  ...rest
}) => (
  <div className={classNames(`Select Select_${className}`, { Select_Error: error })}>
    {label && <div className="Select_Label">{label}</div>}
    {showTooltip ? (
      <div className="Select_FakeBlock">
        {value.label}
        <img data-tip={tooltipContent} className="Select_InfoImg" src={infoIcon} alt="Info hover" />
        <ReactTooltip className="Select_Tooltip" multiline effect="solid" />
      </div>
    ) : (
      <ReactSelect
        {...rest}
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={disabled}
        classNamePrefix="react-select"
      />
    )}
    {error && <span className="Error">{error}</span>}
  </div>
);

export default Select;

import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/agent';
import * as endpoints from '@/const/api/agents';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import history from '@/utillity/history';
import getLink from '@/utillity/routes';

export function* getAgentProfileSaga(action) {
  yield put({ type: actionTypes.GET_AGENT.START });
  const { id } = action.payload;
  try {
    const { data } = yield axios.get(getLink(endpoints.GET_AGENT, { id }));

    yield put({
      type: actionTypes.GET_AGENT.SUCCESS,
      payload: { data },
    });
    yield put({
      type: actionTypes.GET_AGENT.STOP,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_AGENT.FAIL,
    });

    if (error.response.status !== 403) {
      yield call(history.goBack);
      yield call(openNotificationWithIcon, {
        type: 'error',
        message: 'Something went wrong',
        description: getErrorMessage(error),
      });
    }
  }
}

// const test = "agent": {
//         "Id": 3257,
//         "AgentName": "Test",
//         "AgentSurname": "Igor Update",
//         "AgentGender": "m",
//         "AgentMobilePhone": "+123457324446",
//         "AgentLogin": "457324446",
//         "CategoryName": "Сore Team",
//         "CategoryChangedOn": "2019-12-26 14:53:09.000",
//         "City": "Chiatura",
//         "Country": "GE",
//         "Region": "Tbilisi 4",
//         "CreatedOn": "2019-12-26 11:08:06.000",
//         "AgentBirthdate": "2019-12-27",
//         "AgentHeight": 178,
//         "AgentPassport": "12345",
//         "AgentWeight": 66,
//         "AgentChest": 80,
//         "AgentShoeSize": 38,
//         "AgentEmailAddress": "test146@local.com",
//         "AgentWaist": 67,
//         "AgentHips": 86,
//         "Hostess": 0,
//         "AgentClothingSize": "L",
//         "Languages": [
//             {
//                 "Id": 401,
//                 "Name": "AZ",
//                 "Level": "Intermediate"
//             }
//         ],
//         "Trainings": [
//             {
//                 "Id": 190,
//                 "Name": "test123"
//             },
//             {
//                 "Id": 191,
//                 "Name": "test2321"
//             }
//         ],
//         "LastTesting": null,
//         "Aprovals": {
//             "Account": {
//                 "ApprovalId": 31,
//                 "BTLApproved": true,
//                 "DTCApproved": false,
//                 "DTCRejected": true
//             },
//             "Category": {
//                 "ApprovalId": 32,
//                 "BTLApproved": false,
//                 "DTCApproved": false,
//                 "DTCRejected": false
//             },
//             "Status": null
//         },
//         "Images": {
//             "Avatar": "http://pws.loc/storage/files/agent-3257/1577373133-avatar.png",
//             "Document": "http://pws.loc/storage/files/agent-3257/1577373132-document.png",
//             "FullHeight": null
//         },
//         "AdditionalInformation": "Information",
//         "WorkExperience": "Experience",
//         "Recommendations": "Recommendations",
//         "BTLSupervisor": {
//             "Id": 1220,
//             "UserName": "Name BTL supervisor",
//             "UserSurname": "Surname BTL supervisor"
//         },
//         "History": [
//             {
//                 "CategoryFrom": null,
//                 "CategoryTo": "Potential reserve",
//                 "Date": "2019-12-26 11:08:06.000",
//                 "User": {
//                     "Id": 1220,
//                     "UserName": "Name BTL supervisor",
//                     "UserSurname": "Surname BTL supervisor"
//                 }
//             },
//             {
//                 "CategoryFrom": "Potential reserve",
//                 "CategoryTo": "Newcomers",
//                 "Date": "2019-12-26 13:45:50.000",
//                 "User": {
//                     "Id": 1134,
//                     "UserName": "Dana",
//                     "UserSurname": "Vereshchagina"
//                 }
//             },
//             {
//                 "CategoryFrom": "Newcomers",
//                 "CategoryTo": "Сore Team",
//                 "Date": "2019-12-26 14:53:09.000",
//                 "User": {
//                     "Id": 1223,
//                     "UserName": "Name DTC Manager",
//                     "UserSurname": "Surname DTC Manager"
//                 }
//             }
//         ]
//     };

export default getAgentProfileSaga;

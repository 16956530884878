import * as Yup from 'yup';

export default () => {
  return {
    activity_id: Yup.object().nullable(),
    year: Yup.object().nullable(),
    album: Yup.array().of(
      Yup.object().shape({
        image: Yup.mixed().test(
          'image',
          'Image is too large, should be no more than 10 MB',
          item => {
            return item.size < 10000000;
          },
        ),
        consultant_id: Yup.string().required('Consultant is required'),
        outlate_id: Yup.string().required('Outlet is required'),
        date: Yup.string()
          .nullable()
          .required('Date is required'),
      }),
    ),
  };
};

import './popup.scss';
import PopupResults from './popupResults';
import PopupArchiveInfo from './popupArchiveInfo';
import PopupCloseAccess from './popupCloseAccess';
import PopupAddedSuccess from './popupAddedSuccess';
import PopupApprovalRequest from './popupApprovalRequest';

export {
  PopupResults,
  PopupArchiveInfo,
  PopupCloseAccess,
  PopupAddedSuccess,
  PopupApprovalRequest,
};

import * as actionTypes from '@/store/actions/types/approvals';
import * as authActionTypes from '../actions/types/auth';

const initialState = {
  list: [],
  errors: {
    list: null,
    approvalsCounts: null,
  },
  loadings: {
    list: false,
    approvalsCounts: false,
  },
  total_pages: 1,
  current_page: 1,
  approvalsCounts: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_APPROVALS.START:
      return {
        ...state,
        list: [],
        loadings: {
          ...state.loadings,
          list: true,
        },
      };
    case actionTypes.GET_APPROVALS.SUCCESS:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          list: false,
        },
        list: action.payload.data,
        total_pages: action.payload.meta.last_page,
        current_page: action.payload.meta.current_page,
      };
    case actionTypes.GET_APPROVALS.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          list: false,
        },
        errors: {
          ...state.loadings,
          list: action.payload.error,
        },
      };

    case actionTypes.APPROVALS_COUNTS.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          approvalsCounts: true,
        },
      };
    case actionTypes.APPROVALS_COUNTS.SUCCESS:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          approvalsCounts: false,
        },
        approvalsCounts: action.payload.data,
      };
    case actionTypes.APPROVALS_COUNTS.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          approvalsCounts: false,
        },
        errors: {
          ...state.loadings,
          approvalsCounts: action.payload.error,
        },
      };

    case authActionTypes.AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;

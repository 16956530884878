/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from '../../assets/images/jti-header-logo.svg';
import { PopupCloseAccess } from '../parts/Popups';
import './footer.scss';

class Footer extends Component {
  render() {
    const { notEnoughRights } = this.props;
    return (
      <footer className="Footer">
        {notEnoughRights && <PopupCloseAccess />}
        <div className="Footer_cont">
          <p>© Copyright JT International SA 2010-2020. All rights reserved.</p>
          <img src={Logo} alt="" />
        </div>
      </footer>
    );
  }
}

const mapStateToProps = ({ notEnoughRights }) => {
  return {
    notEnoughRights,
  };
};

export default connect(mapStateToProps)(Footer);

import * as actionTypes from '@/store/actions/types/dtcManger';

export const updateDtcManager = (id, requestData, callback = () => {}) => {
  return {
    type: actionTypes.DTC_MANAGER_UPDATE,
    payload: {
      id,
      requestData,
      callback,
    },
  };
};

export default updateDtcManager;

// BTL_SUPERVISORS actions
export const BTL_SUPERVISOR_CREATE = 'BTL_SUPERVISOR_CREATE';
export const BTL_SUPERVISOR_CREATE_START = 'BTL_SUPERVISOR_CREATE_START';
export const BTL_SUPERVISOR_CREATE_SUCCESS = 'BTL_SUPERVISOR_CREATE_SUCCESS';
export const BTL_SUPERVISOR_CREATE_FAILED = 'BTL_SUPERVISOR_CREATE_FAILED';

export const BTL_SUPERVISOR_EDIT_INFO = 'BTL_SUPERVISOR_EDIT_INFO';
export const BTL_SUPERVISOR_EDIT_INFO_START = 'BTL_SUPERVISOR_EDIT_INFO_START';
export const BTL_SUPERVISOR_EDIT_INFO_SUCCESS = 'BTL_SUPERVISOR_EDIT_INFO_SUCCESS';
export const BTL_SUPERVISOR_EDIT_INFO_FAILED = 'BTL_SUPERVISOR_EDIT_INFO_FAILED';

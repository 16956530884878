import * as actionTypes from '@/store/actions/types/regions';

const initialState = {
  list: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGIONS_GET_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REGIONS_GET_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.data.data,
        loading: false,
      };
    case actionTypes.REGIONS_GET_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;

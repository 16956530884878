import * as Yup from 'yup';

export const checkLangLength = item => {
  const arr = item.reduce((acc, language) => {
    if (language.active) {
      acc.push(language);
    }

    return acc;
  }, []);

  return !!arr.length;
};

export const checkLangLevel = item => {
  const arr = item.reduce((acc, language) => {
    if (language.active) {
      acc.push(language);
    }
    return acc;
  }, []);
  return arr.every(el => el.level);
};

export const reqTrainings = item => {
  if (!item || item.length === 0) return;
  const arr = item.reduce((acc, training) => {
    if (training.name) {
      acc.push(training);
    }
    return acc;
  }, []);
  return arr.every(el => el.name);
};

export const phoneValidate = (name = 'MobilePhone') => {
  return Yup.string()
    .required('Phone number is required')
    .test(name, 'Phone number is invalid', phone => {
      if (phone) {
        if (phone.match(/^374/gi)) {
          return phone.length === 11;
        }
        return phone.length === 12;
      }
    });
};

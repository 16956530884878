import CommentsIcon from './Comments';
import Results from './Results';
import EfficientIcon from './Efficient';
import Edit from './Edit';
import Managers from './Managers';
import Consultants from './Consultants';
import Archive from './Archive';
import Testing from './Testing';
import Materials from './Materials';
import Cities from './Cities';
import ArrowLeft from './ArrowLeft';
import Time from './Time';
import Approval from './Approval';
import Calendar from './Calendar';
import Date from './Date';
import Event from './Event';
import User from './User';
import Add from './Add';
import XMLReport from './XMLReport';
import Download from './Download';

export {
  ArrowLeft,
  CommentsIcon,
  Results,
  EfficientIcon,
  Edit,
  Managers,
  Consultants,
  Archive,
  Testing,
  Materials,
  Cities,
  Time,
  Approval,
  Calendar,
  Date,
  Event,
  User,
  Add,
  XMLReport,
  Download,
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Comments = props => {
  const { color, width, height, withBorder, isActive, activeColor } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      className="Comments"
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#12ABAE" />
          <stop offset="100%" stopColor="#88C69A" />
        </linearGradient>
      </defs>
      {withBorder && (
        <rect
          width="39"
          height="39"
          x=".5"
          y=".5"
          stroke={isActive ? activeColor : color}
          fill={isActive ? color : 'none'}
          rx="2"
        />
      )}
      <path
        fill="none"
        stroke={isActive ? activeColor : color}
        strokeWidth="1.5"
        className="Hover-Stroke"
        d="M13.114 29.544l6.91-3.84H31a.25.25 0 0 0 .25-.25V10a.25.25 0 0 0-.25-.25H9a.25.25 0 0 0-.25.25v15.455c0 .138.112.25.25.25h4.114v3.839z"
      />
      <path
        fill={isActive ? activeColor : color}
        className="Hover"
        d="M13.455 14.455h13.091v1.636H13.455zM13.455 18.818h13.091v1.636H13.455z"
      />
    </svg>
  );
};

Comments.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  withBorder: PropTypes.bool,
  isActive: PropTypes.bool,
};

Comments.defaultProps = {
  color: 'url(#gradient)',
  width: '40px',
  height: '40px',
  withBorder: true,
  isActive: false,
};

export default Comments;

import React from 'react';
import PropTypes from 'prop-types';

const Results = ({ color, width, height, withBorder }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 40 40">
    {withBorder && <rect width="39" height="39" x=".5" y=".5" stroke={color} fill="none" rx="2" />}
    <g fill={color}>
      <path
        className="Hover"
        d="M31 27.6v2.2H13.4v-2.2zM31 23.2v2.2H13.4v-2.2zM31 18.8V21H13.4v-2.2zM31 14.4v2.2H13.4v-2.2zM31 10v2.2H13.4V10z"
      />
      <path
        className="Hover"
        d="M11.2 27.6v2.2H9v-2.2zM11.2 23.2v2.2H9v-2.2zM11.2 18.8V21H9v-2.2zM11.2 14.4v2.2H9v-2.2zM11.2 10v2.2H9V10z"
      />
    </g>
  </svg>
);

Results.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  withBorder: PropTypes.bool,
};

Results.defaultProps = {
  width: '40px',
  height: '40px',
  withBorder: true,
};

export default Results;

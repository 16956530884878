import * as actionTypes from '@/store/actions/types/comments';

const initialState = {
  list: [],
  comment: null,
  errors: {
    list: null,
    comment: null,
  },
  loadings: {
    list: false,
    comment: false,
  },
  total_pages: 1,
  current_page: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMMENTS_LIST_START:
      return {
        ...state,
        loadings: { ...state.loadings, list: true },
      };

    case actionTypes.GET_COMMENTS_LIST_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, list: false },
        list: action.payload.data,
        total_pages: action.payload.meta.last_page,
        current_page: action.payload.meta.current_page,
      };

    case actionTypes.GET_COMMENTS_LIST_FAILED:
      return {
        ...state,
        loadings: { ...state.loadings, list: false },
        errors: { ...state.loadings, list: action.payload.error },
      };

    case actionTypes.ADD_COMMENT_START:
      return {
        ...state,
        loadings: { ...state.loadings, comment: true },
      };

    case actionTypes.ADD_COMMENT_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, comment: false },
        comment: action.payload.data,
      };

    case actionTypes.ADD_COMMENT_FAILED:
      return {
        ...state,
        loadings: { ...state.loadings, comment: false },
        errors: { ...state.loadings, comment: action.payload.error },
      };

    default:
      return state;
  }
};

export default reducer;

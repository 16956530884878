import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/managers';
import * as endpoints from '@/const/api/managers';
import getLink from '@/utillity/routes';

export function* getManagersListSaga(action) {
  yield put({ type: actionTypes.GET_MANAGERS.START });

  const { type, ...params } = action.payload;
  try {
    const {
      data: { data, meta },
    } = yield axios.get(getLink(endpoints.GET_MANAGERS_LIST, { type }), { params });

    yield put({
      type: actionTypes.GET_MANAGERS.SUCCESS,
      payload: { data, meta },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_MANAGERS.FAIL,
      payload: { error },
    });
  }
}

export default getManagersListSaga;

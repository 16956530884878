import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from '../actions/notification';

const initialState = {
  notificationText: '',
};

export default function(state = initialState, action) {
  // // console.log("state", state);
  switch (action.type) {
    case ADD_NOTIFICATION:
      return { ...state, notificationText: action.payload };
    case REMOVE_NOTIFICATION:
      return { ...state, notificationText: undefined };
    case CLEAR_NOTIFICATIONS:
      return state;
    default:
      return state;
  }
}

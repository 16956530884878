/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

import './radio-group.scss';

class RadioGroup extends React.PureComponent {
  render() {
    const { data, label, onChange, value, error } = this.props;

    return (
      <div className="RadioGroup">
        <div className="RadioGroup_Header">{label}</div>
        {data &&
          data.map(row => {
            return (
              <div
                role="presentation"
                className={`RadioGroup_Field ${row.value === value ? 'active' : ''}`}
                key={row.value}
                onClick={() => {
                  onChange(row.value);
                }}
              >
                <div className="RadioGroup_box" />

                <label className="RadioGroup_Label" htmlFor="">
                  {row.label}
                </label>
              </div>
            );
          })}

        {error && <span className="Error">{error}</span>}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default RadioGroup;

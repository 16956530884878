/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import * as actionTypes from '../actions/types/auth';

const checkToken = () => !!localStorage.getItem('token');

const initialState = {
  user: null,
  permissions: [],
  isAuth: checkToken(),
  loading: false,
  errors: {
    errorLogin: null,
    errorForgot: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.AUTH_USER_START:
      return { ...state, loading: true };
    case actionTypes.AUTH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        isAuth: true,
      };

    case actionTypes.AUTH_GET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
        loading: false,
        isAuth: true,
      };

    case actionTypes.AUTH_USER_FAIL:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          errorLogin: action.payload,
        },
      };

    case actionTypes.AUTH_FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        errors: {
          ...state.errors,
          errorForgot: action.payload,
        },
      };

    case actionTypes.AUTH_LOGOUT:
      return {
        ...initialState,
        isAuth: false,
      };
    default:
      return state;
  }
}

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import './root.scss';

import Header from '@/components/Header';
import Footer from '@/components/Footer';
// import Spinner from '../parts/Spinner';
import * as routePaths from '@/const/routes';
import LayoutRoute from './help/LayoutRoute';

const Login = lazy(() => import('@/containers/Auth/Login'));
const ForgotPassword = lazy(() => import('@/containers/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('@/containers/Auth/ResetPassword'));
const Logout = lazy(() => import('@/components/Logout'));
const Dashboard = lazy(() => import('@/components/Dashboard'));
const Personals = lazy(() => import('@/containers/BtlConsultants'));
const Managers = lazy(() => import('@/containers/Managers'));
const Employee = lazy(() => import('@/containers/Employee'));
const EmployeeEdit = lazy(() => import('@/containers/Employee/Edit'));
const EmployeeCreate = lazy(() => import('@/containers/Employee/Create'));
const BtlSupervisorCreate = lazy(() => {
  return import('@/containers/BtlSupervisors/BtlSupervisorsCreate');
});
const BtlSupervisorEdit = lazy(() => {
  return import('@/containers/BtlSupervisors/BtlSupervisorEdit');
});
const BtlSupervisorProfile = lazy(() => {
  return import('@/containers/BtlSupervisors/BtlSupervisorsProfile');
});
const BtlManagerProfile = lazy(() => import('@/containers/BtlManagers/BtlManagerProfile'));
const BtlManagerCreate = lazy(() => import('@/containers/BtlManagers/BtlManagerCreate'));
const BtlManagerEdit = lazy(() => import('@/containers/BtlManagers/BtlManagerEdit'));
const DtcManagerProfile = lazy(() => import('@/containers/DtcManagers/DtcManagerProfile'));
const DtcManagerEdit = lazy(() => import('@/containers/DtcManagers/DtcManagerEdit'));
const Regions = lazy(() => import('@/containers/Regions'));
// const City = lazy(() => import('@/components/City'));
// const CreateCity = lazy(() => import('@/components/City/CreateCity'));
const Cabinet = lazy(() => import('@/containers/Cabinet'));
const OnlineTestingContainer = lazy(() => import('@/containers/Testing/onlineTestingContainer'));
const TestingCreate = lazy(() => import('@/containers/Testing/Create'));
const TestingEdit = lazy(() => import('@/containers/Testing/Edit'));
const PassTesting = lazy(() => import('@/containers/Testing/passTesting'));
const TestingHistory = lazy(() => import('@/containers/Testing/results/testingHistory'));
const PhotoArchive = lazy(() => import('@/containers/PhotoArchive/PhotoArchive'));
const photoArchiveSingle = lazy(() => {
  return import('@/containers/PhotoArchive/PhotoArchiveSingle/PhotoArchiveSingle');
});
const photoArchiveSingleAddPhoto = lazy(() => {
  return import('@/containers/PhotoArchive/PhotoArchiveSingle/PhotoArchiveSingleAddPhoto');
});
const Materials = lazy(() => import('@/containers/Materials'));

// TODO: удалити коли поправлять бек
// Запроси через axios.defaults.baseURL не проходять
// щоб не втрачати час прийшлося напряму звертатися до CRM
// і основне, замінити CRM_URL на axios
export const CRM_URL = axios.create({
  baseURL: process.env.REACT_APP_BASE,
  timeout: 30000,
});

class Root extends React.Component {
  render() {
    const { isAuth } = this.props;

    if (!isAuth) {
      return (
        <Switch>
          <LayoutRoute exact path={routePaths.ROUTE_ROOT} component={Login} />
          <LayoutRoute exact path={routePaths.ROUTE_LOGIN} component={Login} />
          <LayoutRoute exact path={routePaths.ROUTE_FORGOT_PASSWORD} component={ForgotPassword} />
          <LayoutRoute exact path={routePaths.ROUTE_RESET_PASSWORD} component={ResetPassword} />
          <Route render={() => <Redirect to={routePaths.ROUTE_ROOT} />} />
        </Switch>
      );
    }

    return (
      <div className="Main">
        <Header />
        <main className="Main_Content">
          <Switch>
            <LayoutRoute
              exact
              path={routePaths.ROUTE_ROOT}
              isAuth={isAuth}
              component={Dashboard}
              protection
            />
            <LayoutRoute
              exact
              path={routePaths.ROUTE_DASHBOARD}
              isAuth={isAuth}
              component={Dashboard}
              protection
            />
            <LayoutRoute
              path={routePaths.ROUTE_MANAGERS}
              isAuth={isAuth}
              component={Managers}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_BTL_SUPERVISOR_CREATE}
              isAuth={isAuth}
              component={BtlSupervisorCreate}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_BTL_SUPERVISOR_EDIT}
              isAuth={isAuth}
              component={BtlSupervisorEdit}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_BTL_SUPERVISOR_PROFILE}
              isAuth={isAuth}
              component={BtlSupervisorProfile}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_BTL_MANAGER_PROFILE_CREATE}
              isAuth={isAuth}
              component={BtlManagerCreate}
              protection
            />
            <LayoutRoute
              path={routePaths.ROUTE_BTL_MANAGER_PROFILE_EDIT}
              isAuth={isAuth}
              component={BtlManagerEdit}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_BTL_MANAGER_PROFILE}
              isAuth={isAuth}
              component={BtlManagerProfile}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_DTC_MANAGER_PROFILE_EDIT}
              isAuth={isAuth}
              component={DtcManagerEdit}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_DTC_MANAGER_PROFILE}
              isAuth={isAuth}
              component={DtcManagerProfile}
              protection
            />

            <LayoutRoute
              exact
              path={routePaths.ROUTE_TEST_HISTORY}
              isAuth={isAuth}
              component={TestingHistory}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_CONSULTANT_EDIT}
              isAuth={isAuth}
              component={EmployeeEdit}
              protection
            />
            <LayoutRoute
              path={routePaths.ROUTE_CONSULTANT_ADD}
              isAuth={isAuth}
              component={EmployeeCreate}
              protection
            />
            <LayoutRoute
              path={routePaths.ROUTE_CONSULTANT_PROFILE}
              isAuth={isAuth}
              component={Employee}
              protection
            />
            <LayoutRoute
              path={routePaths.ROUTE_CONSULTANTS}
              isAuth={isAuth}
              component={Personals}
              protection
            />

            <LayoutRoute
              isAuth={isAuth}
              path={routePaths.ROUTE_ARCHIVE_DETAIL_ADD_PHOTO}
              component={photoArchiveSingleAddPhoto}
              protection
            />

            <LayoutRoute
              isAuth={isAuth}
              path={routePaths.ROUTE_ARCHIVE_DETAIL}
              component={photoArchiveSingle}
              protection
            />

            <LayoutRoute
              path={routePaths.ROUTE_PHOTO_ARCHIVE}
              isAuth={isAuth}
              component={PhotoArchive}
              protection
            />

            <LayoutRoute
              exact
              path={routePaths.ROUTE_TESTING}
              isAuth={isAuth}
              component={OnlineTestingContainer}
              protection
            />
            <LayoutRoute
              exact
              path={routePaths.ROUTE_TESTING_CREATE}
              isAuth={isAuth}
              component={TestingCreate}
              protection
            />
            <LayoutRoute
              exact
              path={routePaths.ROUTE_TESTING_EDIT}
              isAuth={isAuth}
              component={TestingEdit}
              protection
            />
            <LayoutRoute
              exact
              path={routePaths.ROUTE_TESTING_TEST}
              isAuth={isAuth}
              component={PassTesting}
              protection
            />

            <LayoutRoute
              exact
              path={routePaths.ROUTE_MATERIALS}
              isAuth={isAuth}
              component={Materials}
              protection
            />

            <LayoutRoute
              exact
              path={routePaths.ROUTE_REGIONS}
              isAuth={isAuth}
              component={Regions}
              protection
            />
            {/* <LayoutRoute */}
            {/*  path={routePaths.ROUTE_CITIES_CREATE} */}
            {/*  isAuth={isAuth} */}
            {/*  component={CreateCity} */}
            {/*  protection */}
            {/* /> */}
            {/* <LayoutRoute */}
            {/*  path={routePaths.ROUTE_CITIES_INFO} */}
            {/*  isAuth={isAuth} */}
            {/*  component={City} */}
            {/*  protection */}
            {/* /> */}
            <LayoutRoute
              path={routePaths.ROUTE_CABINET}
              isAuth={isAuth}
              component={Cabinet}
              protection
            />
            <LayoutRoute
              path={routePaths.ROUTE_LOGOUT}
              isAuth={isAuth}
              component={Logout}
              protection
            />
            <Route render={() => <div className="Approve_Empty">Page 404</div>} />
          </Switch>
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isUserReady: state.auth.isUserReady,
  isAuth: state.auth.isAuth,
});

export default withRouter(connect(mapStateToProps)(Root));

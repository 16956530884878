/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
// import PropTypes from 'prop-types';
import classNames from 'class-names';
import './menu-icon.scss';

const MenuIcon = ({ toggleMenu, isActive }) => (
  <div
    role="presentation"
    className={classNames('MenuIcon', {
      'MenuIcon-isActive': isActive,
    })}
    onClick={toggleMenu}
  >
    <span className="MenuIcon_Line" />
    <span className="MenuIcon_Line" />
    <span className="MenuIcon_Line" />
  </div>
);

// MenuIcon.propTypes = {
//   toggleMenu: PropTypes.func.isRequired,
// };

export default MenuIcon;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

const Date = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <path d="M-3-3h24v24H-3z" />
      <g fill="#8A97A0">
        <path d="M8.941 17.882c-4.93 0-8.941-4.01-8.941-8.94S4.011 0 8.941 0a.559.559 0 0 1 0 1.118c-4.314 0-7.823 3.51-7.823 7.823 0 4.314 3.51 7.824 7.823 7.824 4.314 0 7.824-3.51 7.824-7.824a.559.559 0 1 1 1.117 0c0 4.93-4.01 8.941-8.94 8.941z" />
        <path d="M8.941 1.118a.588.588 0 0 1-.397-.162.588.588 0 0 1-.162-.397c0-.145.062-.29.162-.397a.585.585 0 0 1 .794 0c.1.106.162.252.162.397 0 .145-.061.29-.162.397a.588.588 0 0 1-.397.162z" />
        <path
          fillRule="nonzero"
          d="M16.496 6.913a.56.56 0 0 1 .397-.682.558.558 0 0 1 .688.391.566.566 0 0 1-.397.687.644.644 0 0 1-.145.017.56.56 0 0 1-.543-.413zm-.782-1.884h.006a.559.559 0 0 1 .966-.559.558.558 0 0 1-.206.76.571.571 0 0 1-.28.079c-.19 0-.38-.1-.486-.28zm-1.241-1.62a.557.557 0 1 1 .788-.788h.006a.561.561 0 0 1-.006.788.535.535 0 0 1-.39.162.542.542 0 0 1-.398-.162zm-1.62-1.246a.551.551 0 0 1-.201-.76.557.557 0 0 1 .76-.207.565.565 0 0 1 .207.765.563.563 0 0 1-.487.28.571.571 0 0 1-.28-.078zm-1.883-.777a.568.568 0 0 1-.397-.687.56.56 0 0 1 .682-.397h.005a.56.56 0 1 1-.145 1.1.61.61 0 0 1-.145-.016z"
        />
        <path d="M17.324 9.5a.575.575 0 0 1-.397-.162.585.585 0 0 1-.162-.397c0-.145.06-.29.162-.397.2-.206.58-.206.793 0 .1.107.162.252.162.397 0 .145-.061.29-.162.397a.588.588 0 0 1-.396.162zM12.294 11.735a.561.561 0 0 1-.31-.094L8.631 9.406a.56.56 0 0 1-.249-.465V3.353a.559.559 0 0 1 1.118 0v5.29l3.104 2.069a.558.558 0 0 1-.31 1.023z" />
      </g>
    </g>
  </svg>
);
export default Date;

import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ color, width, height, withBorder }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 40 40"
  >
    {withBorder && <rect width="39" height="39" x=".5" y=".5" stroke={color} rx="2" />}
    <path
      fill={color}
      className="Hover"
      fillRule="nonzero"
      d="M29.825 10.138c-1.513-1.517-4.154-1.517-5.67 0L10.71 23.608c-.128.128-.22.29-.264.466L9.03 29.752a1.001 1.001 0 0 0 1.214 1.218l5.67-1.42a.991.991 0 0 0 .465-.264l13.446-13.47A3.995 3.995 0 0 0 31 12.976c0-1.072-.416-2.08-1.175-2.838zM15.16 27.67l-3.78.946.945-3.786 11.124-11.143 2.835 2.84L15.159 27.67zm13.249-13.274l-.708.71-2.835-2.84.708-.71c.757-.758 2.078-.758 2.835 0 .378.38.587.883.587 1.42 0 .538-.209 1.041-.587 1.42z"
    />
  </svg>
);

Edit.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  withBorder: PropTypes.bool,
};

Edit.defaultProps = {
  width: '40px',
  height: '40px',
  withBorder: true,
};

export default Edit;

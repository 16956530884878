import * as actionTypes from '@/store/actions/types/passTest';

const initialState = {
  testData: null,
  result: null,
  loadings: {
    testData: true,
    answer: false,
    result: false,
  },
  errors: {
    testData: null,
    answer: null,
    result: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TEST.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          testData: true,
        },
      };
    case actionTypes.GET_TEST.SUCCESS:
      return {
        ...state,
        testData: action.payload.data,
        loadings: {
          ...state.loadings,
          testData: false,
        },
      };
    case actionTypes.GET_TEST.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          testData: false,
        },
        errors: {
          ...state.errors,
          testData: action.payload.error,
        },
      };

    case actionTypes.SET_TEST_ANSWER.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          answer: true,
        },
      };
    case actionTypes.SET_TEST_ANSWER.SUCCESS:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          answer: false,
        },
      };
    case actionTypes.SET_TEST_ANSWER.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          answer: false,
        },
        errors: {
          ...state.errors,
          answer: action.payload.error,
        },
      };

    case actionTypes.SET_TEST_COMPLETE.START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          result: true,
        },
      };
    case actionTypes.SET_TEST_COMPLETE.SUCCESS:
      return {
        ...state,
        result: action.payload.data,
        loadings: {
          ...state.loadings,
          result: false,
        },
      };
    case actionTypes.SET_TEST_COMPLETE.FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          result: false,
        },
        errors: {
          ...state.errors,
          result: action.payload.error,
        },
      };

    case actionTypes.CLEAR_PASS_TEST.INIT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;

import * as actionTypes from './types/approvals';
import { get } from '@/utillity/createAction';

export const getApprovalsList = params => get(actionTypes.GET_APPROVALS.INIT, params);

export const getApprovalsCounts = () => get(actionTypes.APPROVALS_COUNTS.INIT);

export const requestApprovalChanges = (id, type, user) => {
  return {
    type: actionTypes.APPROVALS_REQUEST_CHANGES,
    payload: {
      id,
      type,
      user,
    },
  };
};

export default getApprovalsList;

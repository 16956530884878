// PHOTO ARCHIVE actions
export const PHOTO_GET_LIST = 'PHOTO_GET_LIST';
export const PHOTO_GET_LIST_START = 'PHOTO_GET_LIST_START';
export const PHOTO_GET_LIST_SUCCESS = 'PHOTO_GET_LIST_SUCCESS';
export const PHOTO_GET_LIST_FAILED = 'PHOTO_GET_LIST_FAILED';

export const DETAIL_GET_LIST = 'DETAIL_GET_LIST';
export const DETAIL_GET_LIST_START = 'DETAIL_GET_LIST_START';
export const DETAIL_GET_LIST_SUCCESS = 'DETAIL_GET_LIST_SUCCESS';
export const DETAIL_GET_LIST_FAILED = 'DETAIL_GET_LIST_FAILED';

export const SELECT_GET_LIST = 'SELECT_GET_LIST';
export const SELECT_GET_LIST_START = 'SELECT_GET_LIST_START';

export const SELECT_YEAR_LIST_SUCCESS = 'SELECT_YEAR_LIST_SUCCESS';
export const SELECT_ACTIVITY_LIST_SUCCESS = 'SELECT_ACTIVITY_LIST_SUCCESS';
export const SELECT_SUPERVISORS_LIST_SUCCESS = 'SELECT_SUPERVISORS_LIST_SUCCESS';

export const SELECT_GET_LIST_STOP = 'SELECT_GET_LIST_STOP';
export const SELECT_GET_LIST_CLEAN = 'SELECT_GET_LIST_CLEAN';
export const SELECT_GET_LIST_FAILED = 'SELECT_GET_LIST_FAILED';

export const SLIDER_PHOTO_GET_LIST = 'SLIDER_PHOTO_GET_LIST';
export const SLIDER_PHOTO_GET_LIST_START = 'SLIDER_PHOTO_GET_LIST_START';
export const SLIDER_PHOTO_GET_LIST_SUCCESS = 'SLIDER_PHOTO_GET_LIST_SUCCESS';
export const SLIDER_PHOTO_GET_LIST_FAILED = 'SLIDER_PHOTO_GET_LIST_FAILED';

export const CREATE_ALBUM = 'CREATE_ALBUM';
export const CREATE_ALBUM_START = 'CREATE_ALBUM_START';
export const CREATE_ALBUM_SUCCESS = 'CREATE_ALBUM_SUCCESS';
export const CREATE_ALBUM_FAILED = 'CREATE_ALBUM_FAILED';

export const OUTLET_GET_LIST = 'OUTLET_GET_LIST';
export const OUTLET_GET_LIST_START = 'OUTLET_GET_LIST_START';
export const OUTLET_GET_LIST_SUCCESS = 'OUTLET_GET_LIST_SUCCESS';
export const OUTLET_GET_LIST_STOP = 'OUTLET_GET_LIST_STOP';
export const OUTLET_GET_LIST_FAILED = 'OUTLET_GET_LIST_FAILED';

export const DELETE_PHOTO = 'DELETE_PHOTO';
export const DELETE_PHOTO_START = 'DELETE_PHOTO_START';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILED = 'DELETE_PHOTO_FAILED';

export const ADD_PHOTO_TO_ALBUM = 'ADD_PHOTO_TO_ALBUM';
export const ADD_PHOTO_TO_ALBUM_START = 'ADD_PHOTO_TO_ALBUM_START';
export const ADD_PHOTO_TO_ALBUM_SUCCESS = 'ADD_PHOTO_TO_ALBUM_SUCCESS';
export const ADD_PHOTO_TO_ALBUM_FAILED = 'ADD_PHOTO_TO_ALBUM_FAILED';

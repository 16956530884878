import * as Yup from 'yup';

export default () => {
  return {
    userType: Yup.object().required('User type is required'),
    login: Yup.string().when('userType', {
      is: userType => userType.value === 'api',
      then: Yup.string()
        .required('Login is required')
        .email('Login must be a valid email'),
      otherwise: Yup.string(),
    }),
    phone: Yup.string().when('userType', {
      is: userType => userType.value === 'api-agents',
      then: Yup.string()
        .required('Phone number is required')
        .test('phone', 'Phone number is invalid', phone => {
          if (phone) {
            if (phone.match(/^374/gi)) {
              return phone.length === 11;
            }
            return phone.length === 12;
          }
        }),
      otherwise: Yup.string(),
    }),
    password: Yup.string().required('Password is required'),
  };
};

// -- Endpoints --

// Agents
export const AGENTS_EXPORT = 'api/agents/export';
export const GET_AGENTS_LIST = 'api/agents/list';
export const AGENT_CREATE = '/api/agent';
export const GET_AGENT = '/api/agent/:id';
export const GET_AGENT_EDIT = '/api/agent/:id/edit';

export default GET_AGENTS_LIST;

import * as actionTypes from '@/store/actions/types/test';

const initialState = {
  loading: false,
  test: null,
  types: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TEST_ADD.START:
    case actionTypes.TEST_GET_TEST.START:
    case actionTypes.TEST_GET_TYPES.START:
    case actionTypes.TEST_EDIT.START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TEST_ADD.STOP:
    case actionTypes.TEST_GET_TEST.STOP:
    case actionTypes.TEST_GET_TYPES.STOP:
    case actionTypes.TEST_EDIT.STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.TEST_GET_TYPES.SUCCESS:
      return {
        ...state,
        types: action.payload,
      };
    case actionTypes.TEST_GET_TEST.SUCCESS:
      return {
        ...state,
        test: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

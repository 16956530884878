import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import './select-range.scss';

const SelectRange = ({ values, name, label, onChange, onBlur, options, error }) => {
  const name_from = `${name}_from`;
  const name_to = `${name}_to`;

  const getValue = (opt, value) => {
    if (!opt) return null;
    return opt.find(el => el.value.toString() === value);
  };

  return (
    <div className="SelectRange">
      <div className="SelectRange_Label">{label}</div>
      <div className="SelectRange_Sections">
        <div className="SelectRange_Section">
          <ReactSelect
            key={name_from}
            options={options}
            value={getValue(options, values[name_from])}
            classNamePrefix="react-select"
            placeholder="From"
            onChange={onChange(name_from)}
            onBlur={onBlur(name_from)}
          />
        </div>
        <div className="SelectRange_Separator" />
        <div className="SelectRange_Section">
          <ReactSelect
            key={name_to}
            options={options}
            value={getValue(options, values[name_to])}
            classNamePrefix="react-select"
            placeholder="To"
            onChange={onChange(name_to)}
            onBlur={onBlur(name_to)}
          />
        </div>
      </div>
      {error && <span className="Error">{error}</span>}
    </div>
  );
};

SelectRange.defaultProps = {
  onBlur: () => {},
};

SelectRange.propTypes = {
  values: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.array.isRequired,
  error: PropTypes.string,
};

export default SelectRange;

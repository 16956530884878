import React from 'react';
import PropTypes from 'prop-types';

const popupAddedSuccess = ({ type, onClose, onSubmit }) => {
  const onClosePopup = e => {
    e.preventDefault();
    onClose();
  };

  const formatTitle = () => {
    switch (type) {
      case 'Account':
        return 'Are you sure you want to submit a user account for approval?';
      case 'Status':
        return 'Are you sure you want to submit status changes for approval?';
      default:
        return '';
    }
  };

  return (
    <div className="Popup fadein">
      <div className="Popup-Bg" />
      <div className="Popup-Wrapper">
        <div className="Popup-Content">
          <div className="Popup-Inner">
            <div className="Popup-Block">
              <button type="button" className="Popup-Close" onClick={onClosePopup} />
              <h4 className="Popup-Title">{formatTitle()}</h4>
              <div className="Popup-Bottom">
                <button type="button" className="Btn Popup-Btn" onClick={onClosePopup}>
                  <span>NO</span>
                </button>
                <button type="button" className="Btn Btn-Green Popup-Btn" onClick={onSubmit}>
                  <span>YES</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

popupAddedSuccess.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default popupAddedSuccess;

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import FilterForm from '../forms/FilterForm';
import './search-filter.scss';
import CloseGreenSVG from '../../assets/images/close-green.svg';

class SearchFilter extends Component {
  render() {
    const {
      isActive,
      onClose,
      handleChange,
      handleReset,
      filtersData,
      dictionaries,
      loading,
      handleSubmit,
    } = this.props;

    if (!isActive) {
      return null;
    }

    return (
      <div className="SearchFilter">
        <div className="SearchFilter_Caption">
          <h3 className="SearchFilter_Header">Advanced Search</h3>
          <button type="button" className="SearchFilter_Close" onClick={onClose}>
            <img src={CloseGreenSVG} alt="" />
          </button>
        </div>
        <FilterForm
          filtersData={filtersData}
          onSubmit={handleSubmit}
          handleChange={handleChange}
          onClose={onClose}
          handleReset={handleReset}
          dictionaries={dictionaries}
          loading={loading}
        />
      </div>
    );
  }
}

export default SearchFilter;

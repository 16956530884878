import React from 'react';
import PropTypes from 'prop-types';

const Archive = ({ color, width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 80 80">
    <defs>
      <linearGradient id="gradient" x1="0%" y1=".483%" y2="99.517%">
        <stop offset="0%" stopColor="#12ABAE" />
        <stop offset="100%" stopColor="#88C69A" />
      </linearGradient>
    </defs>
    <path
      fill={color}
      d="M12.858 1.43V7.86h-3.57a1.428 1.428 0 0 0-.134 0c-.438.039-.833.278-1.072.647L.226 20.648a1.429 1.429 0 0 0-.045.112 1.429 1.429 0 0 0-.022.022l-.045.09-.022.044c-.008.03-.016.06-.022.09a1.429 1.429 0 0 0-.045.133 1.429 1.429 0 0 0 0 .045 1.429 1.429 0 0 0-.022.112v.022a1.429 1.429 0 0 0 0 .111c-.004.06-.004.12 0 .179V40a1.429 1.429 0 0 0 0 .112v18.46C.003 59.36.643 60 1.43 60h59.993c.79 0 1.429-.64 1.429-1.429V40.157a1.429 1.429 0 0 0 0-.157 1.429 1.429 0 0 0 0-.111V21.54a1.429 1.429 0 0 0 0-.223v-.045a1.429 1.429 0 0 0-.023-.089v-.067a1.429 1.429 0 0 0-.2-.469 1.429 1.429 0 0 0-.09-.111L54.773 8.506a1.429 1.429 0 0 0-1.205-.647h-3.57V1.43A1.429 1.429 0 0 0 48.702.002a1.428 1.428 0 0 0-.134 0H14.287c-.789 0-1.428.64-1.429 1.428zm1.938.514H48.06v10.914H14.796V1.944zm7.213 4.486a1.43 1.43 0 0 0-1.361 1.495c.037.79.706 1.138 1.495 1.101h18.57c.515.007.994-.133 1.253-.578.26-.445.26-.865 0-1.31a1.429 1.429 0 0 0-1.254-.708H22.143a1.428 1.428 0 0 0-.134 0zm-9.15 3.371v3.057H11.43c-.789 0-1.428.64-1.428 1.429V20h-6.88l6.446-10.2h3.29zm37.138 0h3.29l6.446 10.2h-6.88v-5.714a1.429 1.429 0 0 0-1.294-1.429 1.428 1.428 0 0 0-.133 0h-1.429V9.801zm-38.058 5h38.978v5.2H11.939v-5.2zM1.94 21.944h58.975v17.542H1.94V21.944zm20.917 4.485c-.789 0-1.428.64-1.428 1.429v5.714c0 .79.64 1.429 1.428 1.429h17.141c.79 0 1.429-.64 1.429-1.429v-5.714a1.429 1.429 0 0 0-1.295-1.429 1.428 1.428 0 0 0-.134 0h-17.14zm16.632 1.943v4.686H23.366v-4.686H39.49zM1.94 41.43h58.976v16.628H1.94V41.43zm19.489 5v5.714c0 .79.64 1.429 1.428 1.429h17.141c.79 0 1.429-.64 1.429-1.429V46.43A1.429 1.429 0 0 0 40.132 45a1.428 1.428 0 0 0-.134 0h-17.14c-.79 0-1.429.64-1.43 1.43zm18.06.514v4.686H23.366v-4.686H39.49z"
      transform="translate(5 5)"
    />
  </svg>
);

Archive.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Archive.defaultProps = {
  width: '40px',
  height: '40px',
  color: 'url(#gradient)',
};

export default Archive;

import { notification } from 'antd';

export function* openNotificationWithIcon(data) {
  const { type, message, description } = data;
  yield notification[type]({
    message,
    description,
    placement: 'bottomRight',
    className: `${type}-notification`,
  });
}

export default openNotificationWithIcon;

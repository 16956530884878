import * as actionTypes from '@/store/actions/types/photoArchive';

const initialState = {
  list: [],
  slider: [],
  activities: [],
  supervisors: [],
  years: [],
  outlets: [],
  data: null,
  errors: {
    list: null,
    select: null,
    slider: null,
    data: null,
    outlets: null,
  },
  loadings: {
    list: false,
    select: false,
    slider: false,
    data: false,
    outlets: false,
  },
  totalPages: 1,
  currentPage: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PHOTO_GET_LIST_START:
      return {
        ...state,
        loadings: { ...state.loadings, list: true },
      };
    case actionTypes.PHOTO_GET_LIST_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, list: false },
        list: action.payload.data,
        totalPages: action.payload.meta.last_page,
        currentPage: action.payload.meta.current_page,
      };
    case actionTypes.PHOTO_GET_LIST_FAILED:
      return {
        ...state,
        loadings: { ...state.loadings, list: false },
        errors: { ...state.errors, list: action.payload.error },
      };
    case actionTypes.DETAIL_GET_LIST_START:
      return {
        ...state,
        loadings: { ...state.loadings, list: true },
      };
    case actionTypes.DETAIL_GET_LIST_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, list: false },
        list: action.payload.data,
        totalPages: action.payload.meta.last_page,
        currentPage: action.payload.meta.current_page,
      };
    case actionTypes.DETAIL_GET_LIST_FAILED:
      return {
        ...state,
        loadings: { ...state.loadings, list: false },
        errors: { ...state.errors, list: action.payload.error },
      };
    case actionTypes.SELECT_GET_LIST_START:
      return {
        ...state,
        loadings: { ...state.loadings, select: true },
      };
    case actionTypes.SELECT_YEAR_LIST_SUCCESS:
      return {
        ...state,
        years: action.payload.data,
      };
    case actionTypes.SELECT_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        activities: action.payload.data,
      };
    case actionTypes.SELECT_SUPERVISORS_LIST_SUCCESS:
      return {
        ...state,
        supervisors: action.payload.data,
      };
    case actionTypes.SELECT_GET_LIST_STOP:
      return {
        ...state,
        loadings: { ...state.loadings, select: false },
      };
    case actionTypes.SELECT_GET_LIST_CLEAN:
      return {
        ...state,
        loadings: { ...state.loadings, select: initialState.loadings.select },
        activities: initialState.activities,
        supervisors: initialState.supervisors,
        years: initialState.years,
      };
    case actionTypes.SELECT_GET_LIST_FAILED:
      return {
        ...state,
        loadings: { ...state.loadings, select: false },
        errors: { ...state.errors, select: action.payload.error },
      };
    case actionTypes.SLIDER_PHOTO_GET_LIST_START:
      return {
        ...state,
        loadings: { ...state.loadings, slider: true },
      };
    case actionTypes.SLIDER_PHOTO_GET_LIST_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, slider: false },
        slider: action.payload.data,
      };
    case actionTypes.SLIDER_PHOTO_GET_LIST_FAILED:
      return {
        ...state,
        loadings: { ...state.loadings, slider: false },
        errors: { ...state.errors, slider: action.payload.error },
      };
    case actionTypes.CREATE_ALBUM_START:
      return {
        ...state,
        loadings: { ...state.loadings, data: true },
      };
    case actionTypes.CREATE_ALBUM_SUCCESS:
      return {
        ...state,
        outlets: [],
        loadings: { ...state.loadings, data: false },
        data: action.payload.data,
      };
    case actionTypes.CREATE_ALBUM_FAILED:
      return {
        ...state,
        loadings: { ...state.loadings, data: false },
        errors: { ...state.errors, data: action.payload.error },
      };
    case actionTypes.OUTLET_GET_LIST_START:
      return {
        ...state,
        loadings: { ...state.loadings, outlets: true },
      };
    case actionTypes.OUTLET_GET_LIST_SUCCESS:
      return {
        ...state,
        outlets:
          action.payload.meta && action.payload.meta.current_page === 1
            ? action.payload.data
            : state.outlets.concat(action.payload.data),
        currentPage: action.payload.meta.current_page,
        totalPages: action.payload.meta.last_page,
      };
    case actionTypes.OUTLET_GET_LIST_STOP:
      return {
        ...state,
        loadings: { ...state.loadings, outlets: false },
      };
    case actionTypes.OUTLET_GET_LIST_FAILED:
      return {
        ...state,
        loadings: { ...state.loadings, outlets: false },
        errors: { ...state.errors, outlets: action.payload.error },
      };
    default:
      return state;
  }
};

export default reducer;

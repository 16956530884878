import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/comments';
import getLink from '@/utillity/routes';
import * as endpoints from '@/const/api/comments';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import history from '@/utillity/history';
import { ROUTE_CONSULTANT_PROFILE, ROUTE_BTL_SUPERVISOR_PROFILE } from '@/const/routes';

export function* getCommentsListSaga(action) {
  yield put({ type: actionTypes.GET_COMMENTS_LIST_START });
  const { id, type, ...params } = action.payload;

  try {
    const {
      data: { data, meta },
    } = yield axios.get(getLink(endpoints.GET_COMMENTS, { type, userId: id }), { params });

    yield put({
      type: actionTypes.GET_COMMENTS_LIST_SUCCESS,
      payload: { data, meta },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COMMENTS_LIST_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* addCommentSaga(action) {
  yield put({ type: actionTypes.ADD_COMMENT_START });
  const { type, id, Comment } = action.payload;
  const url = getLink(endpoints.ADD_COMMENT, { type, userId: id });

  try {
    const {
      data: { data },
    } = yield axios.post(url, { Comment });

    yield put({
      type: actionTypes.ADD_COMMENT_SUCCESS,
      payload: { data },
    });

    yield call(openNotificationWithIcon, {
      type: 'success',
      message: 'Successfully added',
      description: data,
    });
    const path = type === 'agent' ? ROUTE_CONSULTANT_PROFILE : ROUTE_BTL_SUPERVISOR_PROFILE;

    yield call(history.replace, {
      pathname: getLink(path, { id }),
      search: '?tab=comment',
    });
  } catch (error) {
    yield put({
      type: actionTypes.ADD_COMMENT_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export default getCommentsListSaga;

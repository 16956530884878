/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Обновление состояния, чтобы при последующей отрисовке показать аварийный UI.
    console.log({ error });
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // Вы можете отрисовать любой резервный UI
      return <h1 className="Approve_Empty">Some errors occurs, try to reload page</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

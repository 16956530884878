import * as actionTypes from '@/store/actions/types/dictionaries';

const initialState = {
  data: null,
  dataSupervisor: null,
  dataSupervisorRegion: null,
  loadings: {
    data: true,
    dataSupervisor: true,
    dataSupervisorRegion: true,
  },
  errors: {
    data: null,
    dataSupervisor: null,
    dataSupervisorRegion: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DICTIONARIES_GET_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          data: true,
        },
        errors: {
          ...state.errors,
          data: null,
        },
      };
    case actionTypes.DICTIONARIES_GET_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loadings: {
          ...state.loadings,
          data: false,
        },
      };
    case actionTypes.DICTIONARIES_GET_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          data: false,
        },
        errors: {
          ...state.errors,
          data: action.payload.error,
        },
      };
    case actionTypes.DICTIONARIES_SUPERVISOR_GET_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          dataSupervisor: true,
        },
        errors: {
          ...state.errors,
          dataSupervisor: null,
        },
      };
    case actionTypes.DICTIONARIES_SUPERVISOR_GET_SUCCESS:
      return {
        ...state,
        dataSupervisor: action.payload.data,
        loadings: {
          ...state.loadings,
          dataSupervisor: false,
        },
      };
    case actionTypes.DICTIONARIES_SUPERVISOR_GET_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          dataSupervisor: false,
        },
        errors: {
          ...state.errors,
          dataSupervisor: action.payload.error,
        },
      };

    case actionTypes.DICTIONARIES_SUPERVISOR_REGION_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          dataSupervisorRegion: true,
        },
        errors: {
          ...state.errors,
          dataSupervisorRegion: null,
        },
      };
    case actionTypes.DICTIONARIES_SUPERVISOR_REGION_SUCCESS:
      return {
        ...state,
        dataSupervisorRegion: action.payload.data,
        loadings: {
          ...state.loadings,
          dataSupervisorRegion: false,
        },
      };
    case actionTypes.DICTIONARIES_SUPERVISOR_REGION_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          dataSupervisorRegion: false,
        },
        errors: {
          ...state.errors,
          dataSupervisorRegion: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

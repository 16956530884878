/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Approval = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="174" height="154" viewBox="0 0 174 154">
    <g fill="none" fillRule="evenodd">
      <path d="M-13-11h200v176H-13z" />
      <g transform="translate(1 1)">
        {/* <circle cx="87" cy="22.5" r="3.75" stroke="#B7C2C9" strokeRigth="1.5" /> */}
        <circle cx="87" cy="22.5" r="3.75" stroke="#B7C2C9" />
        <circle cx="65.5" cy="105" r="1" fill="#B7C2C9" />
        <rect width="39" height="2" x="70.5" y="104" fill="#B7C2C9" rx="1" />
        <rect width="39" height="2" x="70.5" y="115" fill="#B7C2C9" rx="1" />
        <rect width="39" height="2" x="70.5" y="125" fill="#B7C2C9" rx="1" />
        <circle cx="65.5" cy="116" r="1" fill="#B7C2C9" />
        <circle cx="86.5" cy="71" r="19.25" stroke="#B7C2C9" strokeWidth="1.5" />
        <path d="M74.5 59h24v24h-24z" />
        <path
          fill="#B7C2C9"
          fillRule="nonzero"
          d="M86.5 74.71c3.33 0 6.04-3.063 6.04-6.828s-2.71-6.83-6.04-6.83c-1.642 0-3.177.73-4.322 2.053-1.109 1.282-1.719 2.979-1.719 4.777 0 3.765 2.71 6.829 6.041 6.829zm0-12.415c2.59 0 4.698 2.506 4.698 5.587 0 3.08-2.108 5.588-4.698 5.588-2.59 0-4.698-2.507-4.7-5.588 0-3.08 2.108-5.587 4.7-5.587zm4.698 11.175c-.371 0-.672.277-.672.62 0 .343.3.62.672.62 2.59 0 4.698 1.951 4.698 4.347 0 .342-.302.621-.671.621h-17.45c-.37 0-.67-.279-.67-.62 0-2.397 2.107-4.347 4.697-4.347.371 0 .672-.278.672-.621 0-.343-.3-.62-.672-.62-3.33 0-6.04 2.506-6.04 5.587 0 1.028.903 1.863 2.013 1.863h17.45c1.11 0 2.013-.835 2.013-1.863 0-3.08-2.71-5.587-6.04-5.587z"
        />
        <circle cx="65.5" cy="126" r="1" fill="#B7C2C9" />
        <path
          stroke="#B7C2C9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M154.5 10h10-10V0v10zm0 0H145h9.5v9.5V10z"
        />
        <path
          stroke="#B7C2C9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M167.897 58.103H172h-4.103V54v4.103zm0 0H164h3.897V62v-3.897zM24.397 126.103H28.5h-4.103V122v4.103zm0 0H20.5h3.897V130v-3.897z"
        />
        <circle cx="4.5" cy="90.5" r="4.5" stroke="#B7C2C9" transform="rotate(90 4.5 90.5)" />
        <circle cx="168" cy="133" r="3" stroke="#B7C2C9" transform="rotate(90 168 133)" />
        <path
          stroke="#B7C2C9"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M38.423 17.077H41.5h-3.077V14v3.077zm0 0H35.5h2.923V20v-2.923z"
        />
        <path
          stroke="#B7C2C9"
          strokeWidth="1.5"
          d="M126.5 28.75h-80c-6.213 0-11.25 5.037-11.25 11.25v101c0 6.213 5.037 11.25 11.25 11.25h80c6.213 0 11.25-5.037 11.25-11.25V40c0-6.213-5.037-11.25-11.25-11.25z"
        />
        <rect
          width="82.5"
          height="100.5"
          x="45.25"
          y="40.25"
          stroke="#B7C2C9"
          strokeWidth="1.5"
          rx="8"
        />
        <path
          stroke="#B7C2C9"
          strokeWidth="1.5"
          d="M77.5 23v-5a4 4 0 0 1 4-4H92a4 4 0 0 1 4 4v5h17.5a5.5 5.5 0 0 1 0 11H59a5.5 5.5 0 0 1 0-11h18.5z"
        />
      </g>
    </g>
  </svg>
);

Approval.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Approval.defaultProps = {
  width: '174px',
  height: '154px',
  color: 'url(#gradient)',
};

export default Approval;

import * as actionTypes from './types/btlSupervisors';

export const createBtlSupervisor = (requestData, callback = () => {}) => {
  return {
    type: actionTypes.BTL_SUPERVISOR_CREATE,
    payload: {
      requestData,
      callback,
    },
  };
};

export const editBtlSupervisor = (id, requestData, callback = () => {}) => {
  return {
    type: actionTypes.BTL_SUPERVISOR_EDIT_INFO,
    payload: {
      id,
      requestData,
      callback,
    },
  };
};

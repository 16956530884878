import { takeEvery, takeLatest, call } from 'redux-saga/effects';
// Action Types
import * as authActionTypes from '../actions/types/auth';
import * as agentsActionTypes from '../actions/types/agents';
import * as exportActionTypes from '../actions/types/files';
import * as btlSupervisorsActionTypes from '../actions/types/btlSupervisors';
import * as btlManagerActionTypes from '../actions/types/btlManager';
import * as dtcManagerActionTypes from '../actions/types/dtcManger';
import * as dictionariesActionTypes from '../actions/types/dictionaries';
import * as countriesActionTypes from '../actions/types/countries';
import * as managersActionTypes from '../actions/types/managers';
import * as managerActionTypes from '../actions/types/manager';
import * as approvalsActionTypes from '../actions/types/approvals';
import * as testActionTypes from '../actions/types/test';
import * as approvalActionTypes from '../actions/types/approval';
import * as agentActionTypes from '../actions/types/agent';
import * as commentsActionTypes from '../actions/types/comments';
import * as regionsActionTypes from '../actions/types/regions';
import * as photoArchiveActionTypes from '../actions/types/photoArchive';
import * as testsActionTypes from '../actions/types/tests';
import * as passTestActionTypes from '../actions/types/passTest';

// Saga functions
import {
  authSaga,
  authCheckLocalStorageSaga,
  logoutSaga,
  resetPassSaga,
  forgotPassSaga,
} from './auth';
import {
  getAgentsListSaga,
  getAgentsLazyListSaga,
  getAgentInfoEditSaga,
  createUpdateAgentSaga,
} from './agents';
import { exportAgentsListSaga, downloadPhotoSaga } from './files';
import { getRegionsListSaga } from './regions';
import { createBtlSupervisorSaga, editBtlSupervisorSaga } from './btlSupervisors';
import { createBtlManagerSaga, updateBtlManagerSaga } from './btlManager';
import { updateDtcManagerSaga } from './dtcManager';
import { getManagersListSaga } from './managers';
import { getCommentsListSaga, addCommentSaga } from './comments';
import { getManagerProfileSaga } from './managerProfile';
import {
  getApprovalsListSaga,
  getApprovalsCountsSaga,
  requestApprovalChangesSaga,
} from './approvals';
import { sendApprovalSaga } from './approval';
import {
  getDictionariesSaga,
  getDictionariesSupervisorSaga,
  getDictionariesSupervisorRegionSaga,
} from './dictionaries';
import { getCountriesSaga } from './countries';
import { getAgentProfileSaga } from './agent';
import {
  getPhotoListSaga,
  getSelectListSaga,
  getSliderListSaga,
  createAlbumSaga,
  getOutletListSaga,
  getPhotoDetailListSaga,
  deletePhotoSaga,
  addPhotoToAlbumSaga,
} from './photoArchive';
import { getTestTypes, addTest, getTestInfo, editTest } from './test';
import {
  getTestsInstructionsSaga,
  getTestsPassListSaga,
  getTestsResultsSaga,
  getTestsAllListSaga,
  getTestDetailSaga,
  getTestHistorySaga,
  exportTestsPassListSaga,
} from './tests';
import { getTestPassSaga, setTestAnswerSaga, setTestCompleteSaga } from './passTest';

export default function* rootSaga() {
  // Auth
  yield takeEvery(authActionTypes.AUTH_USER, authSaga);
  yield takeEvery(authActionTypes.AUTH_LOGOUT, logoutSaga);
  yield takeEvery(authActionTypes.AUTH_FORGOT_PASSWORD, forgotPassSaga);
  yield takeEvery(authActionTypes.AUTH_RESET_PASSWORD, resetPassSaga);

  // Agents
  yield takeLatest(agentsActionTypes.AGENTS_GET_LIST, getAgentsListSaga);
  yield takeLatest(agentsActionTypes.AGENTS_GET_LAZY_LIST, getAgentsLazyListSaga);
  yield takeEvery(agentsActionTypes.AGENT_GET_INFO_EDIT, getAgentInfoEditSaga);
  yield takeEvery(agentsActionTypes.AGENT_CREATE, createUpdateAgentSaga);
  yield takeEvery(agentsActionTypes.AGENT_UPDATE_INFO_EDIT, createUpdateAgentSaga);
  yield takeEvery(exportActionTypes.EXPORT_AGENTS, exportAgentsListSaga);

  // Agent
  yield takeEvery(agentActionTypes.GET_AGENT.INIT, getAgentProfileSaga);

  // BTL Supervisors
  yield takeEvery(btlSupervisorsActionTypes.BTL_SUPERVISOR_CREATE, createBtlSupervisorSaga);
  yield takeEvery(btlSupervisorsActionTypes.BTL_SUPERVISOR_EDIT_INFO, editBtlSupervisorSaga);

  // BTL Manager
  yield takeEvery(btlManagerActionTypes.BTL_MANAGER_CREATE, createBtlManagerSaga);
  yield takeEvery(btlManagerActionTypes.BTL_MANAGER_UPDATE, updateBtlManagerSaga);

  // DTC Manager
  yield takeEvery(dtcManagerActionTypes.DTC_MANAGER_UPDATE, updateDtcManagerSaga);

  // Dictionaries
  yield takeEvery(dictionariesActionTypes.DICTIONARIES_GET, getDictionariesSaga);
  yield takeEvery(
    dictionariesActionTypes.DICTIONARIES_SUPERVISOR_GET,
    getDictionariesSupervisorSaga,
  );
  yield takeEvery(
    dictionariesActionTypes.DICTIONARIES_SUPERVISOR_REGION,
    getDictionariesSupervisorRegionSaga,
  );

  // Countries
  yield takeLatest(countriesActionTypes.GET_COUNTRIES.INIT, getCountriesSaga);

  // Approval
  yield takeEvery(approvalActionTypes.SEND_APPROVAL.INIT, sendApprovalSaga);

  // Approvals
  yield takeLatest(approvalsActionTypes.GET_APPROVALS.INIT, getApprovalsListSaga);
  yield takeLatest(approvalsActionTypes.APPROVALS_COUNTS.INIT, getApprovalsCountsSaga);
  yield takeLatest(approvalsActionTypes.APPROVALS_REQUEST_CHANGES, requestApprovalChangesSaga);

  // Comments
  yield takeLatest(commentsActionTypes.GET_COMMENTS_LIST, getCommentsListSaga);
  yield takeEvery(commentsActionTypes.ADD_COMMENT, addCommentSaga);

  // Managers
  yield takeLatest(managersActionTypes.GET_MANAGERS.INIT, getManagersListSaga);

  // Manager
  yield takeEvery(managerActionTypes.GET_MANAGER_PROFILE.INIT, getManagerProfileSaga);

  // Cities
  yield takeLatest(regionsActionTypes.REGIONS_GET_LIST, getRegionsListSaga);

  // Photo Archive
  yield takeLatest(photoArchiveActionTypes.PHOTO_GET_LIST, getPhotoListSaga);
  yield takeLatest(photoArchiveActionTypes.SELECT_GET_LIST, getSelectListSaga);
  yield takeLatest(photoArchiveActionTypes.SLIDER_PHOTO_GET_LIST, getSliderListSaga);
  yield takeLatest(photoArchiveActionTypes.DETAIL_GET_LIST, getPhotoDetailListSaga);
  yield takeLatest(photoArchiveActionTypes.OUTLET_GET_LIST, getOutletListSaga);
  yield takeLatest(photoArchiveActionTypes.ADD_PHOTO_TO_ALBUM, addPhotoToAlbumSaga);
  yield takeEvery(photoArchiveActionTypes.CREATE_ALBUM, createAlbumSaga);
  yield takeEvery(photoArchiveActionTypes.DELETE_PHOTO, deletePhotoSaga);
  yield takeEvery(exportActionTypes.DOWNLOAD_FILE, downloadPhotoSaga);

  // TEST
  yield takeEvery(testActionTypes.TEST_GET_TYPES.INIT, getTestTypes);
  yield takeEvery(testActionTypes.TEST_ADD.INIT, addTest);
  yield takeEvery(testActionTypes.TEST_GET_TEST.INIT, getTestInfo);
  yield takeEvery(testActionTypes.TEST_EDIT.INIT, editTest);

  // Tests
  yield takeLatest(testsActionTypes.GET_TESTS_INSTRUCTIONS.INIT, getTestsInstructionsSaga);
  yield takeLatest(testsActionTypes.GET_TESTS_PASS_LIST.INIT, getTestsPassListSaga);
  yield takeLatest(testsActionTypes.GET_TESTS_RESULTS.INIT, getTestsResultsSaga);
  yield takeLatest(testsActionTypes.GET_TESTS_ALL_LIST.INIT, getTestsAllListSaga);
  yield takeLatest(testsActionTypes.GET_TEST_DETAIL.INIT, getTestDetailSaga);
  yield takeLatest(testsActionTypes.GET_TEST_HISTORY_LIST.INIT, getTestHistorySaga);
  yield takeLatest(testsActionTypes.EXPORT_TESTS_PASS_LIST.INIT, exportTestsPassListSaga);

  // Pass test
  yield takeLatest(passTestActionTypes.GET_TEST.INIT, getTestPassSaga);
  yield takeLatest(passTestActionTypes.SET_TEST_ANSWER.INIT, setTestAnswerSaga);
  yield takeLatest(passTestActionTypes.SET_TEST_COMPLETE.INIT, setTestCompleteSaga);

  yield call(authCheckLocalStorageSaga);
}

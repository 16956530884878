import { createActionType } from '@/utillity/createAction';

export const GET_APPROVALS = createActionType('APPROVALS');

export const APPROVALS_COUNTS = createActionType('APPROVALS_COUNTS');

export const APPROVALS_REQUEST_CHANGES = 'APPROVALS_REQUEST_CHANGES';
export const APPROVALS_REQUEST_CHANGES_START = 'APPROVALS_REQUEST_CHANGES_START';
export const APPROVALS_REQUEST_CHANGES_SUCCESS = 'APPROVALS_REQUEST_CHANGES_SUCCESS';
export const APPROVALS_REQUEST_CHANGES_FAILED = 'APPROVALS_REQUEST_CHANGES_FAILED';

export default GET_APPROVALS;

import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';

// Reducers
import auth from './reducers/auth';
// import consultant from './reducers/consultant-reducer';
// import region from './reducers/region-reducer';
import approval from './reducers/approval';
import test from './reducers/test';
import notifications from './reducers/notification';
import notEnoughRights from './reducers/notEnoughEights';
import popups from './reducers/popups';
import agents from './reducers/agents';
import managers from './reducers/managers';
import comments from './reducers/comments';
import managerProfile from './reducers/managerProfile';
import agent from './reducers/agent';
import approvals from './reducers/approvals';
import btlSupervisors from './reducers/btlSupervisors';
import btlManager from './reducers/btlManager';
import dtcManager from './reducers/dtcManager';
import dictionaries from './reducers/dictionaries';
import countries from './reducers/countries';
import files from './reducers/files';
import regions from './reducers/regions';
import photoArchive from './reducers/photoArchive';
import tests from './reducers/tests';
import passTest from './reducers/passTest';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  auth,
  regions,
  approval,
  test,
  notifications,
  notEnoughRights,
  popups,
  files,
  agents,
  btlSupervisors,
  btlManager,
  dtcManager,
  approvals,
  managers,
  managerProfile,
  agent,
  dictionaries,
  comments,
  photoArchive,
  tests,
  passTest,
  countries,
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const configureStore = () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;

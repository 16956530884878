/* eslint-disable guard-for-in */
import axios from 'axios';
/**
 *  getFormData
 *
 * @param {object} formObject
 * @returns {FormData}
 */
export function getFormData(formObject) {
  if (typeof formObject !== 'object') return null;
  const formData = new FormData();
  for (const key in formObject) {
    formData.append(key, formObject[key]);
  }
  return formData;
}

export function selectFormatter(data, relationKey = null) {
  if (!Array.isArray(data)) return [];
  if (relationKey) {
    return data.map(row => ({
      value: row.Id || row.ID,
      label: row.Name,
      [relationKey]: row[relationKey],
    }));
  }
  return data.map(row => ({ value: row.Id || row.ID, label: row.Name }));
}

export function selectFormatterAgents(data) {
  if (!Array.isArray(data)) return [];
  return data.map(row => ({
    value: row.Id,
    label: `${row.AgentName} ${row.AgentSurname}`,
  }));
}

export function promiseGenerator(apiCalls, apiNumber = 0, promises = []) {
  if (apiNumber < apiCalls.length && apiCalls[apiNumber]) {
    const api = apiCalls[apiNumber];
    promises.push(
      axios[api.method](api.url, api.body).then(() => {
        promiseGenerator(apiCalls, apiNumber + 1, promises);
      }),
    );

    return promises;
  }
  return [
    new Promise(res => {
      res();
    }),
  ];
}

export function getSelectValues(form) {
  const resultObj = {};
  for (const key in form) {
    if (form[key] !== undefined && form[key].value !== undefined) {
      resultObj[key] = form[key].value;
    } else if (form[key] && form[key][0] && form[key][0].value) {
      resultObj[key] = form[key][0].value;
    } else {
      resultObj[key] = form[key];
    }
  }
  return resultObj;
}

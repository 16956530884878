/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import getLink from '@/utillity/routes';
import { ROUTE_CONSULTANT_PROFILE } from '@/const/routes';
import CheckAccessHoc from '@/hoc/checkPermitions';
import { Download } from '@/icons';

const PopupArchiveInfo = ({
  closePopup,
  indexActiveItem,
  maxCount,
  item,
  downloadItem,
  setPhotoId,
  showPopupDelete,
  changeItem,
}) => {
  const onDeleteItem = (e, id) => {
    e.preventDefault();

    showPopupDelete(indexActiveItem);
    setPhotoId(id);
  };

  const onDownloadItem = (e, id) => {
    e.preventDefault();

    downloadItem(id);
  };

  const {
    src,
    ConsultantId,
    ConsultantName,
    ConsultantSurName,
    OutletName,
    OutlenAddress,
    OutletId,
    PhotoDate,
    PhotoId,
  } = item;

  return (
    <div className="Popup fadein">
      <div className="Popup-Bg" />
      <div className="Popup-Wrapper">
        <div className="Popup-Content Popup-InfoArchive">
          <div className="Popup-Inner">
            <button type="button" className="Popup-Close" onClick={closePopup} />

            <div className="Popup-InfoArchive-Content">
              <div className="Popup-InfoArchive-Count">
                {indexActiveItem + 1}/{maxCount}
              </div>

              <div
                className="Popup-InfoArchive-Img fadein"
                style={{ backgroundImage: `url(${src})` }}
              />

              <div className="Popup-Block">
                <div className="Popup-InfoArchive-Left">
                  <h4 className="Popup-InfoArchive-Title">
                    <Link
                      to={`${getLink(ROUTE_CONSULTANT_PROFILE, { id: ConsultantId })}?tab=comment`}
                    >
                      {ConsultantName} {ConsultantSurName}
                    </Link>
                  </h4>
                  <div className="Popup-InfoArchive-info">
                    <div className="Popup-InfoArchive-Item">
                      <div className="Popup-InfoArchive-Name">
                        Outlet Name: <span>{OutletName}</span>
                      </div>
                      <div className="Popup-InfoArchive-Name">
                        Outlet Address: <span>{OutlenAddress}</span>
                      </div>
                      <div className="Popup-InfoArchive-Name">
                        Outlet Id: <span>{OutletId}</span>
                      </div>
                      <div className="Popup-InfoArchive-Name">
                        Date: <span>{PhotoDate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Popup-InfoArchive-Right">
                  <CheckAccessHoc rights="album-edit">
                    <button
                      type="button"
                      className="Btn WithIcon"
                      onClick={e => onDeleteItem(e, PhotoId)}
                    >
                      <span className="WithIcon-Img">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="18"
                          viewBox="0 0 72 92"
                        >
                          <defs>
                            <linearGradient id="a" x1="19.842%" x2="80.158%" y1="0%" y2="100%">
                              <stop offset="0%" stopColor="#12ABAE" />
                              <stop offset="100%" stopColor="#88C69A" />
                            </linearGradient>
                          </defs>
                          <g fill="none" fillRule="evenodd">
                            <path fillRule="nonzero" d="M-12-2h96v96h-96z" />
                            <path
                              stroke="url(#a)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2.5"
                              d="M36.408 13.152V9a5 5 0 0 1 5-5h13.683a5 5 0 0 1 5 4.908l.077 4.244H74.92c4.1 0 7.424 3.324 7.424 7.424V26a2 2 0 0 1-2 2H78v56a8 8 0 0 1-8 8H26.5a8 8 0 0 1-8-8V28H16a2 2 0 0 1-2-2v-5.424c0-4.1 3.324-7.424 7.424-7.424h14.984zm23.76 0h-23.76 23.76zM18.5 28H78 18.5zm30 10v42-42zm15 0v42-42zm-30 0v42-42z"
                              transform="translate(-12 -2)"
                            />
                          </g>
                        </svg>
                      </span>
                      <span>Delete</span>
                    </button>
                  </CheckAccessHoc>
                  <CheckAccessHoc rights="download-photo-archive">
                    <button
                      type="button"
                      className="Btn Btn-Green WithIcon"
                      onClick={e => onDownloadItem(e, PhotoId)}
                    >
                      <span className="WithIcon-Img">
                        <Download width="20" />
                      </span>
                      <span>Download</span>
                    </button>
                  </CheckAccessHoc>
                </div>
              </div>

              <button
                type="button"
                className="Popup-InfoArchive-Btn Prev"
                disabled={indexActiveItem === 0}
                onClick={e => {
                  e.preventDefault();
                  changeItem(-1);
                }}
              >
                <span>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
                    <g>
                      <path
                        className="st0"
                        d="m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6,1.6-1.6,4.2 0,5.8l54,53.9z"
                      />
                    </g>
                  </svg>
                </span>
              </button>

              <button
                type="button"
                className="Popup-InfoArchive-Btn Next"
                disabled={indexActiveItem + 1 === maxCount}
                onClick={e => {
                  e.preventDefault();
                  changeItem(1);
                }}
              >
                <span>
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
                    <g>
                      <path
                        className="st0"
                        d="m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6,1.6-1.6,4.2 0,5.8l54,53.9z"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupArchiveInfo;

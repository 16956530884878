import * as actionTypes from './types/dictionaries';

export const getDictionaries = () => {
  return {
    type: actionTypes.DICTIONARIES_GET,
  };
};

export const getDictionariesSupervisor = () => {
  return {
    type: actionTypes.DICTIONARIES_SUPERVISOR_GET,
  };
};

export const getDictionariesSupervisorRegion = RegionId => {
  return {
    type: actionTypes.DICTIONARIES_SUPERVISOR_REGION,
    payload: {
      RegionId,
    },
  };
};

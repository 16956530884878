/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { notEnoughEights } from '../../../store/actions/notEnoughRights';
import IcoCloseAccess from '../../../assets/images/popupCloseAccess/ico-warning.svg';

const PopupCloseAccess = ({ notEnoughEights }) => {
  const onClosePopup = e => {
    e.preventDefault();
    notEnoughEights(false);
  };

  return (
    <div className="Popup fadein">
      <div className="Popup-Bg" />
      <div className="Popup-Wrapper">
        <div className="Popup-Content">
          <div className="Popup-Inner">
            <div className="Popup-Block">
              <button type="button" className="Popup-Close" onClick={onClosePopup} />
              <div className="Popup-Icon">
                <img src={IcoCloseAccess} alt="popup-close-access" />
              </div>
              <h4 className="Popup-Title">Note!</h4>
              <div className="Popup-Text">You do not have enough rights for this action.</div>
              <div className="Popup-Bottom Center">
                <button type="button" className="Btn Btn-Green Popup-Btn" onClick={onClosePopup}>
                  <span>Ok</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { notEnoughEights })(PopupCloseAccess);

// AUTH actions
export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_START = 'AUTH_USER_START';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_FAIL = 'AUTH_USER_FAIL';

export const AUTH_GET_ME_FAIL = 'AUTH_GET_ME_FAIL';

export const AUTH_GET_PERMISSIONS = 'AUTH_GET_PERMISSIONS';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_START = 'AUTH_FORGOT_PASSWORD_START';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAIL = 'AUTH_FORGOT_PASSWORD_FAIL';

export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_START = 'AUTH_RESET_PASSWORD_START';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAIL = 'AUTH_RESET_PASSWORD_FAIL';

import * as actionTypes from './types/photoArchive';

export const createAlbum = (requestData, callback = () => {}) => {
  return {
    type: actionTypes.CREATE_ALBUM,
    payload: { requestData, callback },
  };
};

export const addPhotoToAlbum = (requestData, callback = () => {}) => {
  return {
    type: actionTypes.ADD_PHOTO_TO_ALBUM,
    payload: { requestData, callback },
  };
};

export const getPhotoList = params => {
  return {
    type: actionTypes.PHOTO_GET_LIST,
    payload: { params },
  };
};

export const getPhotoDetailList = (id, params) => {
  return {
    type: actionTypes.DETAIL_GET_LIST,
    payload: { id, params },
  };
};

export const getSelectList = (type, params) => {
  return {
    type: actionTypes.SELECT_GET_LIST,
    payload: { type, params },
  };
};

export const selectListClean = () => {
  return {
    type: actionTypes.SELECT_GET_LIST_CLEAN,
  };
};

export const getOutletsList = params => {
  return {
    type: actionTypes.OUTLET_GET_LIST,
    payload: { params },
  };
};

export const getSliderPhotoList = id => {
  return {
    type: actionTypes.SLIDER_PHOTO_GET_LIST,
    payload: { id },
  };
};

export const deletePhoto = data => {
  return {
    type: actionTypes.DELETE_PHOTO,
    payload: { data },
  };
};

import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = ({ color, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      strokeWidth="2"
      fillRule="nonzero"
      fill="none"
      points="7 13 1 7 7 1"
    />
  </svg>
);

ArrowLeft.propTypes = {
  color: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

ArrowLeft.defaultProps = {
  width: '40px',
  height: '40px',
};

export default ArrowLeft;

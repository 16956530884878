/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { withRouter } from 'react-router-dom';

const PopupResults = ({
  closePopup,
  icon,
  title,
  btnLeft,
  btnRight,
  text,
  btnRightFunction,
  btnLeftFunction,
}) => {
  const onClosePopup = e => {
    e.preventDefault();

    closePopup();
  };

  return (
    <div className="Popup fadein">
      <div className="Popup-Bg" />
      <div className="Popup-Wrapper">
        <div className="Popup-Content">
          <div className="Popup-Inner">
            <div className="Popup-Block">
              {closePopup && (
                <button type="button" className="Popup-Close" onClick={onClosePopup} />
              )}
              <div className="Popup-Icon">
                <img src={icon} alt="Icon" />
              </div>
              <h4 className="Popup-Title">{title}</h4>
              {text && <div className="Popup-Text" dangerouslySetInnerHTML={{ __html: text }} />}
              <div className="Popup-Bottom">
                <button
                  type="button"
                  className="Btn Popup-Btn"
                  onClick={e => {
                    e.preventDefault();
                    btnLeftFunction();
                  }}
                >
                  <span>{btnLeft}</span>
                </button>
                <button
                  type="button"
                  className="Btn Btn-Green Popup-Btn"
                  onClick={e => {
                    e.preventDefault();
                    btnRightFunction();
                  }}
                >
                  <span>{btnRight}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PopupResults);

import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import jwtEncode from '@/utillity/jwtEncode';
import history from '@/utillity/history';
import * as actionTypes from '../actions/types/auth';
import { setAuthorizationToken } from '../configDefaultAPI';
import * as routePaths from '@/const/routes';
import { LOGIN, FORGOT_PASSWORD, RESET_PASSWORD, GET_ME } from '@/const/api/auth';
import phoneRegExp from '@/utillity/phoneRegExp';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';

function* setLocalSaga(token) {
  yield localStorage.setItem('token', token);
  yield localStorage.setItem('expiration', jwtEncode(token).exp);
}

function* getMe() {
  const token = yield localStorage.getItem('token');

  if (token) {
    try {
      const res = yield axios.get(GET_ME);
      const {
        data: { user, permissions },
      } = res;

      yield put({
        type: actionTypes.AUTH_USER_SUCCESS,
        payload: {
          user,
        },
      });
      yield put({
        type: actionTypes.AUTH_GET_PERMISSIONS,
        payload: {
          permissions,
        },
      });
    } catch (error) {
      yield call(openNotificationWithIcon, {
        type: 'error',
        message: 'Something went wrong',
        description: getErrorMessage(error),
      });
    }
  }
}

export function* authSaga(action) {
  yield put({ type: actionTypes.AUTH_USER_START });

  const {
    data: { userType, login, password, phone },
  } = action.payload;

  let body = {
    password,
    user_type: userType.value,
  };

  if (userType.value === 'api-agents') {
    body = { ...body, login: phone };
  } else {
    body = { ...body, login };
  }

  try {
    const url = LOGIN;
    const res = yield axios.post(url, body);
    const { data } = res;

    yield call(setLocalSaga, data.token);
    yield call(setAuthorizationToken, data.token);
    yield call(getMe);

    yield call(history.push, routePaths.ROUTE_ROOT);
  } catch (error) {
    yield put({
      type: actionTypes.AUTH_USER_FAIL,
      payload: getErrorMessage(error),
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

function* checkTokenExpirationDate() {
  const token = yield localStorage.getItem('token');
  const expireTime = +localStorage.getItem('expiration');

  // Check token expiration
  if (expireTime > Math.floor(Date.now() / 1000)) {
    yield call(setAuthorizationToken, token);
    yield call(getMe);
  } else {
    yield put({ type: actionTypes.AUTH_LOGOUT });
  }
}

export function* authCheckLocalStorageSaga() {
  const token = yield localStorage.getItem('token');

  if (token) {
    try {
      yield call(checkTokenExpirationDate);
    } catch (error) {
      yield put({ type: actionTypes.AUTH_LOGOUT });
    }
  }
}

export function* logoutSaga() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('expiration');
  yield call(setAuthorizationToken, null);
}

export function* forgotPassSaga(action) {
  yield put({ type: actionTypes.AUTH_FORGOT_PASSWORD_START });

  const {
    data: { phone },
  } = action.payload;

  const url = FORGOT_PASSWORD;

  try {
    yield axios.post(url, { phone });

    yield put({
      type: actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS,
    });

    yield call(history.push, routePaths.ROUTE_ROOT);
  } catch (error) {
    yield put({
      type: actionTypes.AUTH_FORGOT_PASSWORD_FAIL,
      payload: getErrorMessage(error),
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export function* resetPassSaga(action) {
  yield put({ type: actionTypes.AUTH_RESET_PASSWORD_START });

  const {
    data: { password, phone, code, password_confirmation },
  } = action.payload;
  const url = RESET_PASSWORD;

  try {
    yield axios.post(url, { password, phone: phoneRegExp(phone), code, password_confirmation });

    yield put({
      type: actionTypes.AUTH_RESET_PASSWORD_SUCCESS,
    });

    yield call(history.push, routePaths.ROUTE_ROOT);
  } catch (error) {
    yield put({
      type: actionTypes.AUTH_RESET_PASSWORD_FAIL,
      payload: getErrorMessage(error),
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@/assets/images/ic-search.svg';
import Filter from '@/assets/images/ic-adv-search.svg';
import './search.scss';

class Search extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isShowFilterButton: PropTypes.bool,
  };

  static defaultProps = {
    isShowFilterButton: true,
  };

  render() {
    const {
      handleSubmit,
      onShowHideFilter,
      isShowFilterButton,
      isActiveFilter,
      handleChange,
      value,
    } = this.props;
    return (
      <form
        className="Header_Search Search"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <input
          type="text"
          name="search"
          placeholder="Find by name or phone number"
          className="Search_Input"
          onChange={e => handleChange(e.target.value)}
          value={value}
        />
        {isShowFilterButton && (
          <button
            className="Search_Button Search_Button-isFilter"
            type="button"
            onClick={onShowHideFilter}
          >
            {isActiveFilter && <div className="Search-Badge" />}
            <img src={Filter} alt="" width={24} height={24} />
          </button>
        )}
        <button type="button" className="Search_Button" onClick={handleSubmit}>
          <img src={SearchIcon} alt="" />
        </button>
      </form>
    );
  }
}

export default Search;

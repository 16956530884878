/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'class-names';
import * as queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';
import { isEqual, debounce, isObject } from 'lodash-es';
import Search from '@/components/forms/SearchForm';
import Logo from '@/assets/images/jti-header-logo.svg';
import MenuIcon from '../MenuIcon';
import Notification from '../Notification';
import MainMenu from '../MainMenu';
import SearchFilter from '../SearchFilter';
import './header.scss';
import NotificationSuccess from '../parts/NotificationSuccess';
import { ROUTE_CONSULTANTS } from '@/const/routes';
import { getDictionaries } from '@/store/actions';

const initialParams = {
  search: '',
  CountryId: null,
  regionId: null,
  cityId: null,
  hostess: null,
  status: null,
  cloth_size_from: null,
  cloth_size_to: null,
  shoe_size_from: null,
  shoe_size_to: null,
  gender: '',
  height_from: null,
  height_to: null,
};

class Header extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      isMenuOpen: false,
      isFilterOpen: false,
      isShowFilterButton: props.location.pathname !== '/',
      params: {
        ...initialParams,
        ...queryString.parse(location.search),
      },
    };
    this.searchAgentDebounced = debounce(this.searchAgents, 700);
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;

    if (!isEqual(location, nextProps.location)) {
      const isShowFilterButton = nextProps.location.pathname !== '/';
      this.setState({
        isMenuOpen: false,
        isFilterOpen: false,
        isShowFilterButton,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { params } = this.state;
    const { location, getDictionaries, permissions } = this.props;
    const query = new URLSearchParams(location.search);
    const hasDictionariesPermissions = permissions.includes('get-agent-dictionaries');

    const isConsultantsPage = location.pathname === ROUTE_CONSULTANTS;

    // GET DICTIONARIES
    if (hasDictionariesPermissions && permissions !== prevProps.permissions) {
      getDictionaries();
    }

    // ON entering in ROUTE_CONSULTANTS page.
    // IF params search is empty
    // IF prev params search is empty
    //
    // AND The previous screen was not ROUTE_CONSULTANTS (disabling logic for the back button)
    // OR ROUTE_CONSULTANTS is the current screen
    //
    // AND Search field from params and search field from query params doesn't match
    // AND search field from query params is not empty
    // THEN Set only search param from the query string
    if (
      !params.search &&
      !prevState.params.search &&
      (prevProps.location.pathname !== ROUTE_CONSULTANTS || isConsultantsPage) &&
      params.search !== query.get('search') &&
      query.get('search')
    ) {
      this.setState({ params: { ...params, search: query.get('search') } });
      return;
    }

    // ON change in the search string
    // IF prev search field from params and current search field from params doesn't match
    // AND Search field from params and search field from query params doesn't match
    // AND search field from params is not empty OR ROUTE_CONSULTANTS is the current screen
    // THEN Route to ROUTE_CONSULTANTS with query params
    if (
      prevState.params.search !== params.search &&
      params.search !== query.get('search') &&
      (params.search || isConsultantsPage)
    ) {
      this.searchAgentDebounced();
    }

    // ON leaving from ROUTE_CONSULTANTS
    // IF prev location was a ROUTE_CONSULTANTS
    // AND ROUTE_CONSULTANTS isn't the current screen
    // THEN reset params data
    if (prevProps.location.pathname === ROUTE_CONSULTANTS && !isConsultantsPage) {
      this.setState({ params: { ...initialParams, search: '' } });
    }

    if (location.search !== prevProps.location.search) {
      this.setState({ params: { ...initialParams, ...queryString.parse(location.search) } });
    }
  }

  handleParamsChange = param => value => {
    const { params } = this.state;
    this.setState({ params: { ...params, [param]: value } });
  };

  handleToggleMenu = () => {
    const { isMenuOpen, isFilterOpen } = this.state;
    if (isMenuOpen && isFilterOpen) return;
    this.setState({ isMenuOpen: !isMenuOpen, isFilterOpen: false });
  };

  handleShowHideFilter = () => {
    this.setState(({ isFilterOpen }) => ({
      isFilterOpen: !isFilterOpen,
    }));
  };

  searchAgents = () => {
    const { params } = this.state;
    const { history } = this.props;
    const query = new URLSearchParams(window.location.search);
    const exceptionKeys = ['city', 'region', 'Country'];
    const paramsStatus = params.status ? params.status.value : 0;
    // reset page count before submit new params
    query.delete('page');

    Object.keys(params).forEach(key => {
      let value = params[key];

      if (isObject(params[key])) {
        value = params[key].value;
      }

      const queryKey = exceptionKeys.includes(key) ? `${key}Id` : key;

      if (query.get(queryKey)) {
        query.set(queryKey, value);
      } else {
        query.append(queryKey, value);
      }

      if (!value && value !== 0) {
        query.delete(queryKey);
      }
    });

    switch (paramsStatus) {
      case 1:
        query.set('type', 'potential');
        break;
      case 2:
      case 3:
      case 4:
        query.set('type', 'permanent');
        break;

      case 5:
      case 6:
        query.set('type', 'former');
        break;
      default:
        break;
    }

    history.push(`${ROUTE_CONSULTANTS}?${query.toString()}`);
  };

  setParamsData = (filtersData = initialParams) => {
    // const {
    //   params: { search },
    // } = this.state;

    this.setState({ params: { ...filtersData } }, this.searchAgents);
  };

  checkIsFiltersActive = () => {
    const { params } = this.state;
    const { location } = this.props;
    return !!Object.keys(params).find(key => {
      return (
        !!params[key] &&
        key !== 'type' &&
        !!params[key] &&
        key !== 'search' &&
        !!params[key] &&
        key !== 'tab' &&
        !!params[key] &&
        key !== 'page' &&
        location.pathname === ROUTE_CONSULTANTS
      );
    });
  };

  render() {
    const {
      isMenuOpen,
      isFilterOpen,
      // isShowFilterButton,
      params,
    } = this.state;
    const { dictionaries, loading } = this.props;

    const isActiveFilter = this.checkIsFiltersActive();
    return (
      <header
        className={classNames('Header', {
          'Header-isOpen': isMenuOpen,
          'Header-isFilterOpen': isFilterOpen,
        })}
      >
        <div className="Header_Caption">
          <div className="Logo">
            <Link to="/">
              <img src={Logo} alt="JTI Logo" className="Logo_Image" />
            </Link>
            <p className="Logo_Slogan">
              Consumer Product <br />
              Information Consultation
            </p>
          </div>
          <Search
            isShowFilterButton
            isActiveFilter={isActiveFilter}
            onShowHideFilter={this.handleShowHideFilter}
            handleSubmit={this.searchAgents}
            handleChange={this.handleParamsChange('search')}
            value={params.search}
          />

          <Notification />
          <MenuIcon toggleMenu={this.handleToggleMenu} isActive={isMenuOpen} />
        </div>
        <SearchFilter
          filtersData={params}
          isActive={isFilterOpen}
          onClose={this.handleShowHideFilter}
          handleChange={this.handleParamsChange}
          handleSubmit={this.setParamsData}
          handleReset={this.setParamsData}
          dictionaries={dictionaries}
          loading={loading}
        />
        <NotificationSuccess />
        {isMenuOpen && <MainMenu />}
      </header>
    );
  }
}

const mapStateToProps = ({ auth, dictionaries }) => {
  return {
    dictionaries: dictionaries.data,
    loading: dictionaries.loadings.data,
    permissions: auth.permissions,
  };
};

export default withRouter(connect(mapStateToProps, { getDictionaries })(Header));

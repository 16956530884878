import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/countries';
import * as endpoints from '@/const/api/countries';

export function* getCountriesSaga() {
  yield put({ type: actionTypes.GET_COUNTRIES.START });

  const url = endpoints.GET_COUNTRIES;

  try {
    const {
      data: { data },
    } = yield axios.get(url);

    yield put({
      type: actionTypes.GET_COUNTRIES.SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUNTRIES.FAIL,
      payload: { error },
    });
  }
}

export default getCountriesSaga;

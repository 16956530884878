// Comments actions
export const GET_COMMENTS_LIST = 'GET_COMMENTS_LIST';
export const GET_COMMENTS_LIST_START = 'GET_COMMENTS_LIST_START';
export const GET_COMMENTS_LIST_SUCCESS = 'GET_COMMENTS_LIST_SUCCESS';
export const GET_COMMENTS_LIST_FAILED = 'GET_COMMENTS_LIST_FAILED';

export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_START = 'ADD_COMMENT_START';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILED = 'ADD_COMMENT_FAILED';

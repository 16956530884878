import * as Yup from 'yup';

export default () => {
  return {
    phone: Yup.string()
      .required('Phone number is required')
      .test('phone', 'Phone number is invalid', phone => {
        if (phone) {
          if (phone.match(/^374/gi)) {
            return phone.length === 11;
          }
          return phone.length === 12;
        }
      }),
  };
};

const getLink = (route, params = {}) => {
  let updatedRoute = route;
  Object.keys(params).forEach(param => {
    updatedRoute = updatedRoute.replace(`:${param}`, params[param]);
  });

  return updatedRoute;
};

export const formatSearchParams = (searchParams, key, value, deletePage = true) => {
  const newParams = new URLSearchParams(searchParams);

  if (deletePage) {
    newParams.delete('page');
  }
  if (newParams.get(key)) {
    newParams.set(key, value);
  } else {
    newParams.append(key, value);
  }

  return newParams.toString();
};

export default getLink;

import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as actionTypes from '@/store/actions/types/test';
import * as endpoints from '@/const/api/test';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import history from '@/utillity/history';

export function* getTestTypes() {
  yield put({ type: actionTypes.TEST_GET_TYPES.START });
  // const { ApprovalId, type, userType, callBack } = action.payload;
  try {
    const { data } = yield axios.get(endpoints.TEST_GET_TYPES);
    console.log(data);
    yield put({
      type: actionTypes.TEST_GET_TYPES.SUCCESS,
      payload: data && data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.TEST_GET_TYPES.FAIL,
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  } finally {
    yield put({
      type: actionTypes.TEST_GET_TYPES.STOP,
    });
  }
}

export function* addTest(action) {
  const { ...params } = action.payload;
  yield put({ type: actionTypes.TEST_ADD.START });
  // const { ApprovalId, type, userType, callBack } = action.payload;
  try {
    yield axios.post(endpoints.TEST_ADD, params);
    yield put({
      type: actionTypes.TEST_ADD.SUCCESS,
    });

    yield call(history.goBack);
  } catch (error) {
    yield put({
      type: actionTypes.TEST_ADD.FAIL,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  } finally {
    yield put({
      type: actionTypes.TEST_ADD.STOP,
    });
  }
}

export function* editTest(action) {
  const { ...params } = action.payload;
  yield put({ type: actionTypes.TEST_EDIT.START });

  try {
    yield axios.put(endpoints.TEST_EDIT, params);
    yield put({
      type: actionTypes.TEST_EDIT.SUCCESS,
    });

    yield call(history.goBack);
  } catch (error) {
    yield put({
      type: actionTypes.TEST_EDIT.FAIL,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  } finally {
    yield put({
      type: actionTypes.TEST_EDIT.STOP,
    });
  }
}

export function* getTestInfo(action) {
  const { ...params } = action.payload;
  yield put({ type: actionTypes.TEST_GET_TEST.START });
  // const { ApprovalId, type, userType, callBack } = action.payload;
  try {
    const { data } = yield axios.get(endpoints.TEST_GET_TEST_INFO, { params });
    yield put({
      type: actionTypes.TEST_GET_TEST.SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.TEST_GET_TEST.FAIL,
      payload: { error },
    });
    // yield call(history.goBack);

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  } finally {
    yield put({
      type: actionTypes.TEST_GET_TEST.STOP,
    });
  }
}

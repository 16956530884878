/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
/* eslint-disable no-alert */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

const Event = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
    <g fill="none" fillRule="nonzero">
      <path d="M-2-4h24v24H-2z" />
      <path
        fill="#8A97A0"
        d="M3.823 11.11l-2.409 1.045c-.302.13-.658.114-.944-.042-.285-.156-.459-.43-.46-.726V3.504c.001-.296.175-.57.46-.726.286-.156.642-.172.944-.042l2.409 1.028L16.856.727a1.946 1.946 0 0 1 1.519.27c.43.291.683.743.683 1.221v10.427c0 .85-.782 1.54-1.75 1.542-.152 0-.304-.017-.452-.051l-2.76-.641-.256.84c-.303 1-1.468 1.599-2.608 1.34l-3.898-.919c-1.143-.27-1.82-1.304-1.516-2.31l.25-.816-2.245-.52zm8.894 2.968l.273-.837-5.776-1.343-.277.812c-.139.457.17.927.69 1.049l3.897.918c.518.123 1.05-.145 1.193-.6zM4.345 4.703v5.467l12.82 2.982c.174.04.361.007.504-.09a.495.495 0 0 0 .228-.407V2.218c0-.159-.084-.31-.228-.406a.649.649 0 0 0-.505-.09L4.345 4.702zm-3.169-.939v7.345l2-.863V4.628l-2-.864zm12.983-.209h-.004a.64.64 0 0 1 .565.134c.147.13.204.32.15.497a.554.554 0 0 1-.415.363L6.617 6.376c-.312.073-.632-.09-.715-.365-.083-.275.103-.556.415-.629l7.842-1.827z"
      />
    </g>
  </svg>
);

export default Event;

import * as Yup from 'yup';

export default () => {
  return {
    phone: Yup.string()
      .required('Phone is required')
      .test('errorPhone', 'Phone number is not valid', function testValidate() {
        const { errorPhone } = this.parent;

        return errorPhone;
      }),
    errorPhone: Yup.bool(),
    code: Yup.string().required('Code is required'),
    password: Yup.string().required('Password is required!'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords don't match")
      .required('Password confirm is required'),
  };
};

import * as actionTypes from '../actions/types/popups';

const initialState = {
  type: '',
  albumState: false,
  addedSuccessState: false,
  approvalRequestState: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POPUPS_ADDED_SUCCESS:
      return {
        ...state,
        addedSuccessState: action.payload.state,
      };
    case actionTypes.POPUPS_ALBUM_SUCCESS:
      return {
        ...state,
        type: action.payload.type,
        albumState: action.payload.state,
      };
    case actionTypes.POPUPS_APPROVAL_REQUEST:
      return {
        ...state,
        type: action.payload.type || '',
        approvalRequestState: action.payload.state,
      };
    default:
      return state;
  }
};

import * as actionTypes from '@/store/actions/types/btlSupervisors';

const initialState = {
  editData: null,
  loadings: {
    editData: false,
  },
  errors: {
    editData: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BTL_SUPERVISOR_CREATE_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          editData: true,
        },
        errors: {
          ...state.errors,
          editData: null,
        },
      };
    case actionTypes.BTL_SUPERVISOR_CREATE_SUCCESS:
      return {
        ...state,
        editData: { ...state.editData, btlSupervisor: action.payload.data },
        loadings: {
          ...state.loadings,
          editData: false,
        },
      };
    case actionTypes.BTL_SUPERVISOR_CREATE_FAILED:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          editData: false,
        },
        errors: {
          ...state.errors,
          editData: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

import * as actionTypes from './types/comments';

export const getCommentsList = params => {
  return {
    type: actionTypes.GET_COMMENTS_LIST,
    payload: params,
  };
};

export const addComment = data => {
  return {
    type: actionTypes.ADD_COMMENT,
    payload: data,
  };
};

export const ROUTE_ROOT = '/';

export const ROUTE_DASHBOARD = '/dashboard';

export const ROUTE_LOGIN = '/login';
export const ROUTE_SIGN_UP = '/sign-up';
export const ROUTE_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_RESET_PASSWORD = '/reset-password';

export const ROUTE_MANAGERS = '/managers';
// export const ROUTE_MANAGERS_BTL_SUPERVISORS = `${ROUTE_MANAGERS}/btl-supervisors`;
// export const ROUTE_MANAGERS_BTL_MANAGERS = `${ROUTE_MANAGERS}/btl-managers`;
// export const ROUTE_MANAGERS_DTC_MANAGERS = `${ROUTE_MANAGERS}/dtc-managers`;
export const ROUTE_MANAGERS_LIST = `${ROUTE_MANAGERS}/:type`;
export const ROUTE_BTL_MANAGER_PROFILE = '/btl-manager/:id';
export const ROUTE_BTL_MANAGER_PROFILE_CREATE = '/btl-manager/create';
export const ROUTE_BTL_MANAGER_PROFILE_EDIT = '/btl-manager/:id/edit';
export const ROUTE_DTC_MANAGER_PROFILE = '/dtc-manager/:id';
export const ROUTE_DTC_MANAGER_PROFILE_EDIT = '/dtc-manager/:id/edit';

export const ROUTE_BTL_SUPERVISORS = '/btl-supervisors';
export const ROUTE_BTL_SUPERVISOR_PROFILE = `${ROUTE_BTL_SUPERVISORS}/:id`;
export const ROUTE_BTL_SUPERVISOR_CREATE = `${ROUTE_BTL_SUPERVISORS}/create`;
export const ROUTE_BTL_SUPERVISOR_EDIT = `${ROUTE_BTL_SUPERVISORS}/:id/edit`;

export const ROUTE_CONSULTANTS = '/consultants';
export const ROUTE_CONSULTANT_PROFILE = `${ROUTE_CONSULTANTS}/:id`;
export const ROUTE_CONSULTANT_EDIT = `${ROUTE_CONSULTANTS}/:id/edit`;
export const ROUTE_CONSULTANT_ADD = `${ROUTE_CONSULTANTS}/add`;

export const ROUTE_PHOTO_ARCHIVE = '/photo-archive/:type';
export const ROUTE_ARCHIVE = '/archive-photo';
export const ROUTE_ARCHIVE_DETAIL = `${ROUTE_ARCHIVE}/:id`;
export const ROUTE_ARCHIVE_DETAIL_ADD_PHOTO = `${ROUTE_ARCHIVE_DETAIL}/add-photo`;

export const ROUTE_TESTING = '/testing';
export const ROUTE_TESTING_CREATE = `${ROUTE_TESTING}/create`;
export const ROUTE_TESTING_EDIT = `${ROUTE_TESTING}/edit/:id`;
export const ROUTE_TESTING_TEST = `${ROUTE_TESTING}/pass/:id`;
export const ROUTE_TEST_HISTORY = `${ROUTE_TESTING}/history/:id`;

export const ROUTE_MATERIALS = '/materials';

export const ROUTE_REGIONS = '/regions';
// export const ROUTE_CITIES_INFO = `${ROUTE_REGIONS}/:id`;
// export const ROUTE_CITIES_CREATE = `${ROUTE_REGIONS}/create`;

export const ROUTE_CABINET = '/cabinet';

export const ROUTE_LOGOUT = '/logout';

import axios from 'axios';
import { put, call } from 'redux-saga/effects';
import { isObject, isArray } from 'lodash-es';

import * as actionTypes from '@/store/actions/types/agents';
import * as agentsEndpoints from '@/const/api/agents';
import { openNotificationWithIcon } from '@/store/saga/notifications';
import getErrorMessage from '@/utillity/errors';
import getLink from '@/utillity/routes';

function createFormData(formData, key, data) {
  if (isObject(data) || isArray(data)) {
    if (key === 'Images') {
      // eslint-disable-next-line guard-for-in
      for (const index in data) {
        // eslint-disable-next-line guard-for-in
        for (const itemKey in data[index]) {
          formData.append(`${key}[${index}][${itemKey}]`, data[index][itemKey]);
        }
      }

      return;
    }
    // eslint-disable-next-line guard-for-in
    for (const i in data) {
      createFormData(formData, `${key}[${i}]`, data[i]);
    }
  } else {
    formData.append(key, data);
  }
}

export function* getAgentsListSaga(action) {
  yield put({ type: actionTypes.AGENTS_GET_LIST_START });
  const { params } = action.payload;

  const url = agentsEndpoints.GET_AGENTS_LIST;

  try {
    const { data } = yield axios.get(url, { params });

    yield put({
      type: actionTypes.AGENTS_GET_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.AGENTS_GET_LIST_FAILED,
      payload: { error },
    });
  }
}

export function* getAgentsLazyListSaga(action) {
  yield put({ type: actionTypes.AGENTS_GET_LAZY_LIST_START });
  const { params } = action.payload;

  const url = agentsEndpoints.GET_AGENTS_LIST;

  try {
    const { data } = yield axios.get(url, { params });

    yield put({
      type: actionTypes.AGENTS_GET_LAZY_LIST_SUCCESS,
      payload: { data },
    });

    yield put({
      type: actionTypes.AGENTS_GET_LAZY_LIST_STOP,
    });
  } catch (error) {
    yield put({
      type: actionTypes.AGENTS_GET_LAZY_LIST_FAILED,
      payload: { error },
    });
  }
}

export function* getAgentInfoEditSaga(action) {
  yield put({ type: actionTypes.AGENT_GET_INFO_EDIT_START });

  const { id } = action.payload;
  const url = getLink(agentsEndpoints.GET_AGENT_EDIT, { id });

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.AGENT_GET_INFO_EDIT_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: actionTypes.AGENT_GET_INFO_EDIT_FAILED,
      payload: { error },
    });
  }
}

// TODO: split create and edit
export function* createUpdateAgentSaga(action) {
  yield put({ type: actionTypes.AGENT_UPDATE_INFO_EDIT_START });

  const { type, requestData, callback } = action.payload;
  const url = agentsEndpoints.AGENT_CREATE;
  const isEdit = type === 'edit';

  const formData = new FormData();
  Object.keys(requestData).forEach(key => {
    createFormData(formData, key, requestData[key]);
  });

  if (isEdit) {
    formData.append('_method', 'PUT');
  }

  try {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const { data } = yield axios.post(url, formData, config);

    if (isEdit) {
      yield put({
        type: actionTypes.AGENT_UPDATE_INFO_EDIT_SUCCESS,
        payload: { agent: data.agent },
      });
    } else {
      yield put({
        type: actionTypes.AGENT_CREATE_INFO_SUCCESS,
      });
    }

    callback();

    if (isEdit) {
      yield call(openNotificationWithIcon, {
        type: 'success',
        message: 'Succesfully edited',
        description: 'Employee has been updated',
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.AGENT_UPDATE_INFO_EDIT_FAILED,
      payload: { error },
    });

    yield call(openNotificationWithIcon, {
      type: 'error',
      message: 'Something went wrong',
      description: getErrorMessage(error),
    });
  }
}

export default getAgentsListSaga;
